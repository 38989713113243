<template>
    <div class="purchase-desktop desktop">
        <!-- 采购工作台 -->
        <group-tab :groups="groups" @groupChange="changeOrg"></group-tab>
        <el-scrollbar style="height: 100%">
            <div class="purchase-desktop-main">
                <!-- <weatherSmall /> -->
                <sumDataCard
                    :isMoney="false"
                    cardIcon="iconfont icon-caigougenjin"
                    apiName="getPurchaseFlow"
                    :fields="['pendingReview', 'underReview', 'inExecution']"
                    :items="cggjItems"
                />
                <sumDataCard
                    :isMoney="false"
                    cardTitle="入库结算"
                    cardIcon="iconfont icon-kucunjiankong"
                    class="sum2"
                    apiName="getSettlement"
                    :fields="[
                        'toBeStored',
                        'partialStorage',
                        'storedToBeSettled',
                        'partialSettled'
                    ]"
                    :items="rkjsItems"
                />
                <arrive-monitor
                    cardIcon="iconfont icon-chukuhuozhi"
                    cardLink="/purchase/follow"
                ></arrive-monitor>
                <cash-medium
                    icon="iconfont icon-yingfu"
                    :title="contant.financeDesktopText.ACTUAL_PAY"
                    :sumData="yfSumData"
                    :columns="yfColumns"
                    :datas="yfData"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="yfParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.ACTUAL_PAY
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </cash-medium>
                <Pending class="item-pending" />
                <purchase-range></purchase-range>
                <value-percent></value-percent>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import oaSvc from 'services/oa'
import groupTab from './components/orgs'
import contant from './cards/contant'
import sumDataCard from './cards/sumDataDisplay'
import arriveMonitor from './cards/arriveMonitor'
import cashMedium from './cards/cashMedium'
import Pending from './cards/pending'
import purchaseRange from './cards/purchaseRange'
import valuePercent from './cards/valuePercent'
import { makeDataToMap, assembleStatisticalData } from '@/utils/index'
import financeSvc from 'services/finance'
import weatherSmall from './cards/weatherSmall'
export default {
    name: 'PurchasDesktop',
    components: {
        groupTab,
        cashMedium,
        Pending,
        sumDataCard,
        arriveMonitor,
        purchaseRange,
        valuePercent,
        weatherSmall
    },
    data() {
        return {
            contant,
            partners: [
                {
                    value: 0,
                    label: '全部'
                }
            ],
            groups: [],
            currentGroupInfo: {},

            yfSumData: [],
            yfColumns: ['近7天应付清单'],
            yfData: [],
            yfParenterId: 0,

            hxColumns: [],
            hxSumData: [],
            hxParenterId: 0,
            hxData: [],

            cggjItems: ['待审核', '审核中', '执行中'],
            rkjsItems: ['待入库', '部分入库', '入库待结算', '部分结算']
        }
    },
    created() {
        this.getUserGroups()
        this.getPartners()
        this.getPaymentList()
    },
    methods: {
        async getPartners() {
            try {
                const { data = [] } = await financeSvc.getPartners({ isOwn: 1 })
                let arr = data.map(item => {
                    let o = {}
                    o.value = item.id
                    o.label = item.partnerName
                    return o
                })
                this.partners.push(...arr)
            } catch (error) {
                console.log(error)
            }
        },
        // 应付
        async getPaymentList() {
            try {
                const { data = {} } = await financeSvc.getPaymentList({
                    partnerId: this.yfParenterId,
                    source: 1
                })
                this.yfData = makeDataToMap(
                    data.paymentInfoList,
                    'receiverPartnerAlias',
                    {
                        valueField: 'payAmount',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    },
                    {
                        valueField: 'payingTime'
                    }
                )
                this.yfSumData = assembleStatisticalData(
                    data,
                    {
                        title: '应付总额',
                        valueField: 'totalPayment',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    },
                    {
                        title: '近7天应付',
                        valueField: 'sevenDayPayment',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    }
                )
            } catch (error) {
                console.log(error)
            }
        },
        async getUserGroups() {
            const res = await oaSvc.getEmployeeInfo()
            this.groups = res.data.employeeGroupList
            this.currentGroupInfo = this.groups[0]
        },
        parenterChange(cardName) {
            switch (cardName) {
                case contant.financeDesktopText.GOODS_REST:
                    break
                case contant.financeDesktopText.CASH_REST:
                    break
                case contant.financeDesktopText.CHECK_RECEIVE:
                    this.getCheckReceiveList()
                    break
                case contant.financeDesktopText.ACTUAL_PAY:
                    this.getPaymentList()
                    break

                default:
                    break
            }
        },
        changeOrg(index) {
            this.currentGroupInfo = this.groups[index]
        }
    }
}
</script>

<style lang="scss" scoped>
.purchase-desktop {
    &-main {
        padding: 0.2rem;
        box-sizing: border-box;
        flex: 1;
        width: 100%;
        display: grid;
        grid-template-rows: repeat(5, 2rem);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        .sum2 {
            grid-row-start: 2;
        }
    }
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
}
</style>
