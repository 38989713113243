<template>
    <div class="desktop">
        <ul class="orgs">
            <li
                v-for="(item, index) in groups"
                :key="index"
                :class="{ active: iscur === index }"
                @click="changeOrg(index)"
            >
                {{ item.groupName }}
            </li>
        </ul>
        <el-scrollbar :native="false" :noresize="false" tag="section">
            <div v-if="groups.length > 0" class="content">
                <!-- <div class="content-left"> -->
                <FinanceCard
                    class="item1"
                    :group-id="Number(currentGroupInfo.groupId)"
                />
                <!-- <GoodsOperateChart :title="'TOP5商品经营图'" :options="goods" /> -->
                <GoodsOperateChart
                    ref="goodsOperatorCard"
                    class="item2"
                    :group="currentGroupInfo"
                    :title="currentGroupInfo.leaf ? 'TOP5商品经营图' : 'TOP5店铺经营图'"
                />
                <DistributorCard
                    class="item3"
                    :loading="weekNewLoading"
                    :title="weekNewTitle"
                    :content-data="distributorDatas"
                    :link="'/sell/distribution/distributor/list'"
                />
                <DistributorCard
                    class="item4"
                    :title="discardTitle"
                    :loading="weeDownLoading"
                    :content-data="downDatas"
                    :icon="'iconfont icon-xiaoshouxiahuafenxiaoshang'"
                />
                <!-- </div> -->
                <!-- <div class="content-right"> -->
                <Pending class="item5" />
                <!-- </div> -->
                <!-- </div> -->
                <!-- <div class="content"> -->
                <GoodsSaleChart class="item6" />
                <DistributorSaleChart class="item7" />
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import FinanceCard from './cards/finance'
import DistributorCard from './cards/distributor'
import Pending from './cards/pending'
import GoodsSaleChart from './cards/goodsSale'
import DistributorSaleChart from './cards/distributorSale'
import GoodsOperateChart from './cards/goodsOperate'
import oaSvc from 'services/oa'
import { getWeekNew, getDownTwoMore, getDownYesterday } from 'services/distribution'
export default {
    name: 'DistributorDesktop',
    components: {
        DistributorCard,
        FinanceCard,
        Pending,
        GoodsSaleChart,
        DistributorSaleChart,
        GoodsOperateChart
    },
    data () {
    // 这里存放数据
        return {
            id: '',
            iscur: 0,
            weekNewTitle: '本周新增分销商',
            discardTitle: '销售下滑分销商',
            distributorDatas: [],
            downDatas: [
                {
                    title: '连日销量下滑',
                    datas: []
                },
                {
                    title: '昨日销量下滑',
                    datas: []
                }
            ],
            goods: [],
            groups: [],
            currentGroupInfo: {},
            weekNewLoading: false,
            weeDownLoading: false
        }
    },
    created () {
        this.getWeekNew()
        this.getDownTwoMore()
        this.getUserGroups()
    },
    // 方法集合
    methods: {
        changeOrg (index) {
            this.iscur = index
            this.currentGroupInfo = this.groups[index]
            this.getWeekNew()
            this.getDownTwoMore()
        },
        async getUserGroups () {
            try {
                const { data = {} } = await oaSvc.getEmployeeInfo()
                this.groups = data.employeeGroupList
                this.currentGroupInfo = this.groups[0]
                this.$nextTick(() => {
                    this.$refs.goodsOperatorCard.initData()
                })
            } catch (error) {
                console.log(error)
            }
        },
        // 获取本周新增分销商
        async getWeekNew () {
            try {
                this.weekNewLoading = true
                const res = await getWeekNew()
                this.weekNewLoading = false
                if (res.data && res.data.length > 0) {
                    const levels = [
                        ...new Set(res.data.map((item) => item.distributorLevelMeaning))
                    ]
                    levels.forEach((item) => {
                        const obj = {
                            title: item,
                            datas: []
                        }
                        res.data.forEach((data) => {
                            if (data.distributorLevelMeaning === item) {
                                obj.datas.push(data)
                            }
                        })
                        this.distributorDatas.push(obj)
                    })
                }
            } catch (error) {
                this.weekNewLoading = false
                console.log(error)
            }

            // this.$axios
            //     .fetch('distributionServer', '/distribution/distributor-week-new')
            //     .then((res) => {
            //         if (res.data && res.data.length > 0) {
            //             const levels = [
            //                 ...new Set(res.data.map((item) => item.distributorLevelMeaning))
            //             ]
            //             levels.forEach((item) => {
            //                 const obj = {
            //                     title: item,
            //                     datas: []
            //                 }
            //                 res.data.forEach((data) => {
            //                     if (data.distributorLevelMeaning === item) {
            //                         obj.datas.push(data)
            //                     }
            //                 })
            //                 this.distributorDatas.push(obj)
            //             })
            //         }
            //     })
        },
        // 销售下滑分销商
        async getDownTwoMore () {
            // const res1 = await this.$axios.fetch(
            //     'distributionServer',
            //     '/distribution/distributor-down-two-more'
            // )
            // const res2 = await this.$axios.fetch(
            //     'distributionServer',
            //     '/distribution/distributor-down-yesterday'
            // )
            try {
                this.weeDownLoading = true
                const [res1, res2] = await Promise.all([
                    getDownTwoMore(),
                    getDownYesterday()
                ])
                this.weeDownLoading = false
                // console.log(res1, res2);
                this.downDatas[0].datas = res1.data ? res1.data : []
                this.downDatas[1].datas = res2.data ? res2.data : []
            } catch (error) {
                console.log(error)
                this.weeDownLoading = false
            }
        }
    }
}
</script>
<style lang="less" scoped>
.desktop {
    padding-bottom: 15px;

    .orgs {
        display: flex;
        padding-left: 30px;
        margin-top: 15px;

        li {
            padding: 10px 0;
            margin: 0 20px;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            color: #a7b6c3;
            cursor: pointer;
        }

        li.active {
            position: relative;
            color: #3595e1;
        }

        li.active::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 90%;
            height: 3px;
            content: "";
            background: #3595e1;
            border-radius: 2px;
            transform: translateX(-50%);
        }
    }

    /deep/ .el-scrollbar {
        width: 100%;
        height: 100%;

        .el-scrollbar__wrap {
            overflow-x: hidden !important;
        }
    }

    .content {
        display: grid;
        grid-template-rows: repeat(6, 2rem);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        padding: 0.2rem;
        // display: flex;
        // margin-top: .2rem;
        // padding: 0 .2rem;
        // justify-content: space-between;
        // &-left {
        //     display: flex;
        //     flex-wrap: wrap;
        //     justify-content: space-between;
        //     margin-right: .2rem;
        // }
        .item1 {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .item2 {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .item3 {
            grid-row-start: 3;
            grid-row-end: 5;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .item4 {
            grid-row-start: 3;
            grid-row-end: 5;
            grid-column-start: 3;
            grid-column-end: 5;
        }

        .item5 {
            grid-row-start: 1;
            grid-row-end: 5;
            grid-column-start: 5;
            grid-column-end: 6;
        }

        .item6 {
            grid-row-start: 5;
            grid-row-end: 7;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .item7 {
            grid-row-start: 5;
            grid-row-end: 7;
            grid-column-start: 3;
            grid-column-end: 6;
        }
    }
}
</style>
