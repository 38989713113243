<template>
    <el-dialog
        class="todos-dialog"
        :visible.sync="visible"
        :title="title"
        width="500px"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <!-- 1 -->
        <draggable
            v-model="stickPlans"
            element="ul"
            group="group1"
            animation="150"
            class="plans stick"
        >
            <transition-group>
                <li v-for="item in stickPlans" :key="item.title">
                    <i class="iconfont icon-zhidingjiaobiao"></i>
                    <el-tooltip
                        class="tipItem"
                        effect="dark"
                        content="取消置顶"
                        placement="top"
                    >
                        <span class="icon" @click="cancelTop(item)">
                            <i class="el-icon-download"></i>
                        </span>
                    </el-tooltip>
                    <div class="li-content">
                        <p>{{ item.title }}</p>
                        <p>
                            <!-- {{ item.scheduleStart.substr(0, 10) }}~{{
                                item.scheduleEnd.substr(0, 10)
                            }} -->
                            {{ getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]) }}
                        </p>
                    </div>
                    <div class="li-tags">
                        <span
                            :class="{
                                high: item.priority === 30,
                                mid: item.priority === 20,
                                low: item.priority === 10
                            }"
                        >{{ item.priority | priorityFilter }}</span>
                        <span v-if="item.delay" class="delay">
                            延期
                        </span>
                        <span>{{
                            dicFilter(
                                null,
                                null,
                                item.status,
                                null,
                                todoStatusDicts?todoStatusDicts:[]
                            )
                        }}</span>
                    </div>
                </li>
            </transition-group>
        </draggable><!-- 2 -->
        <draggable
            v-model="tempTops"
            element="ul"
            :group="group2Name"
            disabled
            class="plans stick"
        >
            <li v-for="item in stickCount" :key="item" class="null item">
                <span class="empty">
                    <i class="el-icon-upload2"></i>
                    下方待办可拖入置顶
                </span>
            </li>
        </draggable>
        <!-- 3 -->
        <draggable
            v-model="notStickPlans"
            group="stick"
            element="ul"
            animation="100"
            class="plans not-stick"
            drag-class="dragClass"
            ghost-class="ghostClass"
            chosen-class="chosenClass"
            @end="onEnd"
        >
            <transition-group>
                <li v-for="item in notStickPlans" :key="item.title">
                    <div class="li-content">
                        <p>{{ item.title }}</p>
                        <p>
                            <!-- {{ item.scheduleStart.substr(0, 10) }}~{{
                                item.scheduleEnd.substr(0, 10)
                            }} -->
                            {{ getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]) }}
                        </p>
                    </div>
                    <div class="li-tags">
                        <span
                            :class="{
                                high: item.priority === 30,
                                mid: item.priority === 20,
                                low: item.priority === 10
                            }"
                        >{{ item.priority | priorityFilter }}</span>
                        <span v-if="item.delay" class="delay">
                            延期
                        </span>
                        <span>{{
                            dicFilter(
                                null,
                                null,
                                item.status,
                                null,
                                todoStatusDicts?todoStatusDicts:[]
                            )
                        }}</span>
                    </div>
                </li>
            </transition-group>
        </draggable>
        <!-- 4 -->
        <!-- <draggable
            element="ul"
            :group="stickCount === 7 ? 'stick' : 'not-stick'"
            disabled
            class="plans not-stick"
        > -->
        <ul class="plans not-stick">
            <li v-for="item in notStickCount" :key="item" class="null"></li>
        </ul>
        <!-- </ul> -->
        <!-- </draggable> -->
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import tableMethod from 'utils/table'
import _ from 'lodash'
import todoSvc from 'services/todo'
import { getDateRangeDisplay } from 'utils/date'
export default {
    name: 'TodosDialog',
    components: {},
    filters: {
        priorityFilter: value => {
            switch (value) {
            case 10:
                return '低'
            case 20:
                return '中'
            case 30:
                return '高'
            }
        }
    },
    props: {
        todoStatusDicts: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            visible: false,
            title: '调整',
            stickPlans: [],
            notStickPlans: [],
            tempTops: [],
            group2Name: 'stick',
            employeeName: '',
            employeeId: ''
        }
    },
    computed: {
        stickCount () {
            return 3 - this.stickPlans.length < 0
                ? 0
                : 3 - this.stickPlans.length
        },
        notStickCount () {
            return 7 - this.notStickPlans.length < 0
                ? 0
                : 7 - this.notStickPlans.length
        }
    },
    watch: {
        stickPlans: {
            immediate: true,
            deep: true,
            async handler (newValue, oldValue) {
                console.log(newValue)
                if (newValue.length === 3) {
                    this.group2Name = 'twoFull'
                    // this.tempTops = []
                } else {
                    this.group2Name = 'stick'
                }
            }
        }
    },
    created () {},
    methods: {
        ...tableMethod,
        getDateRangeDisplay,
        async handleConfirm () {
            await todoSvc.saveSomeoneTodo({
                employeeId: this.employeeId,
                employeeName: this.employeeName,
                topTodoList: this.stickPlans,
                otherTodoList: this.notStickPlans
            })
            this.$message.success('操作成功！')
            // this.$listeners.
            // console.log(this.$listeners)
            this.$listeners['handle-refresh']()
            this.visible = false
        },
        handleCancel () {
            this.visible = false
        },
        onEnd () {
            console.log(this.tempTops, '===tempTops')
            const res = _.xorBy(this.tempTops, this.stickPlans, 'id')
            console.log(res)
            if (res && res.length > 0) {
                this.stickPlans.push(res[0])
            }
        },
        cancelTop (item) {
            this.notStickPlans.unshift(item)
            this.stickPlans.forEach((o, index) => {
                if (o.id === item.id) {
                    this.stickPlans.splice(index, 1)
                }
            })
            this.tempTops = _.cloneDeep(this.stickPlans)
        }
    }
}
</script>
<style lang="less" scoped>
.todos-dialog {
    /deep/ .el-dialog__body {
        max-height: 600px;
        overflow-y: auto;

        .plans {
            li {
                position: relative;
                box-sizing: border-box;
                display: flex;
                // flex-direction: column;
                // justify-content: center;
                align-items: center;
                justify-content: space-between;
                height: 62px;
                padding-left: 15px;
                border-bottom: 1px solid #f0f0f0;

                .icon {
                    margin-right: 10px;
                    font-size: 20px;
                    cursor: pointer;
                }

                .li-content {
                    flex: 1;

                    p:nth-child(1) {
                        font-size: 16px;
                        font-weight: 400;
                        color: #606266;
                    }

                    p:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        color: #c0c4cc;
                    }
                }

                .editIcon {
                    position: absolute;
                    top: 50%;
                    right: -10px;
                    transform: translateY(-50%);
                }
            }

            li.null {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .li-tags {
                span {
                    box-sizing: border-box;
                    display: inline-block;
                    height: 20px;
                    padding: 3px;
                    font-size: 12px;
                    line-height: 14px;
                    color: #fff;
                    text-align: center;
                    background-color: #80a3db;
                    border-radius: 4px;
                    transform: scale(0.9);
                }

                span.high,
                span.delay {
                    background-color: #df5a68;
                }

                span.mid {
                    background-color: #e6a23c;
                }

                span + span {
                    margin-left: 4px;
                }
            }
        }

        .plans.stick {
            .icon-zhidingjiaobiao {
                position: absolute;
                top: 0;
                left: 0;
                color: rgb(223, 90, 104);
            }
        }

        .plans.not-stick {
            li {
                padding-left: 25px;
            }
        }
    }
}
</style>
