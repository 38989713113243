<template>
    <div class="edit-zone">
        <div class="config-area">
            <h2 class="component-name">{{ selected.title || '组件名称' }}</h2>
            <h6 class="size-title">设置尺寸：</h6>
            <div class="config-area-main" @mouseleave="handleMouseLeave">
                <grid-layout
                    :layout.sync="viewItems"
                    :col-num="currentCol"
                    :max-rows="currentMaxRowTotal"
                    :row-height="24"
                    :is-draggable="false"
                    :static="true"
                    :is-resizable="false"
                    :is-mirrored="false"
                    :vertical-compact="false"
                    :use-css-transforms="true"
                >
                    <grid-item
                        v-for="item in viewItems"
                        :key="item.i"
                        :x="item.x"
                        :y="item.y"
                        :i="item.i"
                        :w="item.w"
                        :h="item.h"
                    >
                        <div
                            class="static-item"
                            :class="{
                                'item-disabled': item.disabled,
                                hovered: item.hovered
                            }"
                            @mouseover="handleMouseOver(item)"
                            @click="handleItemClick(item)"
                        ></div>
                    </grid-item>
                </grid-layout>
            </div>
            <div class="details">
                <el-image class="logo" fit="fill" :src="selected.iconUrl">
                    <div slot="error" class="el-image__error">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
                <!-- <div class="item">
                    <div class="label">
                        创建人：{{ selected }}
                    </div>
                    <div class="info p12"></div>
                </div> -->
                <div class="item">
                    <div class="label">组件类型：</div>
                    <div v-if="visible" class="info p12">
                        {{
                            selected.widgetType
                                | dictionaryFilter(
                                    vueInstance,
                                    '工作台小部件类型'
                                )
                        }}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        关联模块：
                    </div>
                    <div class="info p12">{{ selected.widgetGroup }}</div>
                </div>
                <div class="item">
                    <div class="label">业务标签：</div>
                    <div class="info">{{ selected.tags }}</div>
                </div>
                <div class="item">
                    <div class="label">简要说明：</div>
                    <div class="info">{{ selected.summary }}</div>
                </div>
                <div v-if="urls.length" class="item component-item">
                    <div class="label">
                        组件预览：
                    </div>
                    <div class="info custom-image-info">
                        <el-image
                            v-for="(url, index) in urls"
                            :key="index"
                            class="custom-image"
                            :src="url"
                            :preview-src-list="urls"
                            lazy
                            :z-index="9999"
                        >
                        </el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { GridLayout, GridItem } from 'vue-grid-layout' // 引入layout布局
import { initTenant } from '../_tenant'
import gbDictionary from '@/components/dictionary'

export default {
    name: 'EditZone',
    components: {
        GridLayout,
        GridItem
    },
    filters: {
        dictionaryFilter: gbDictionary.filter
    },
    props: {
        config: {
            type: Object,
            default: () => {}
        },
        selected: {
            type: Object,
            default: () => {}
        },
        addItems: {
            type: Array,
            default: () => []
        },
        colTotal: {
            type: Number,
            default: 6
        },
        maxRowTotal: {
            type: Number,
            default: 6 // 传入布局最大的行数
        },
        editType: {
            type: String,
            default: 'add'
        }
    },
    data () {
        return {
            visible: false,
            vueInstance: this,
            defaultItems: [],
            currentCol: 5, // 布局视窗最大列数
            currentMaxRowTotal: 6, // 布局视图最大行数
            clickedCoord: null,
            hovered: null,
            urls: [
                // 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
                // 'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
            ]
        }
    },
    computed: {
        disabledItems () {
            const { x, y, i } = this.config.gridBox || {}
            const colTotal = this.colTotal
            const rowTotal = this.maxRowTotal
            const items = this.addItems
                .filter(item => {
                    if (this.editType !== 'add') {
                        return item.i !== i && item.i !== 'default'
                    } else {
                        return item.i !== 'default'
                    }
                })
                .filter(item => {
                    return (
                        (item.x > x && item.y > y) ||
                        (item.x > x && item.y + item.h > y) ||
                        (item.y > y && item.x + item.w > x)
                    )
                })

            const minX = Math.min(...items.map(item => item.x))
            const minXy = Math.min(
                ...items.filter(items => items.x === minX).map(item => item.y)
            )
            const xPoint = {
                x: minX,
                y: minXy
            }
            const minY = Math.min(...items.map(item => item.y))

            const minYx = Math.min(
                ...items.filter(items => items.y === minY).map(item => item.x)
            )
            const yPoint = {
                x: minYx,
                y: minY
            }
            const isRange = val =>
                (val.x + x >= xPoint.x && val.y + y >= xPoint.y) ||
                (val.x + x >= yPoint.x && val.y + y >= yPoint.y)
            const defaultItems = _.cloneDeep(this.defaultItems || [])
            return defaultItems.map(item => {
                item.disabled =
                    item.x >= colTotal - x ||
                    item.y >= rowTotal - y ||
                    isRange(item)
                return item
            })
        },
        viewItems () {
            const isHoverd = val => {
                if (this.hovered) {
                    return (
                        val.y <= this.hovered.y &&
                        val.x <= this.hovered.x &&
                        !val.disabled
                    )
                } else {
                    return (
                        (this.clickedCoord !== null
                            ? val.y <= this.clickedCoord.y &&
                              val.x <= this.clickedCoord.x
                            : this.clickedCoord) && !val.disabled
                    )
                }
            }
            const disabledItems = _.cloneDeep(this.disabledItems || [])
            return disabledItems.map(item => {
                item.hovered = isHoverd(item)
                return item
            })
        },
        viewCoord () {
            return {
                config: this.config && this.config.gridBox ? this.config.gridBox : null,
                disabledItems: this.disabledItems,
                selected: this.selected,
                type: this.editType
            }
        }
    },
    watch: {
        viewCoord: {
            immediate: true,
            deep: true,
            handler ({ type, config, selected, disabledItems }) {
                if (_.isEmpty(selected) || _.isEmpty(disabledItems)) {
                    return
                }
                // 设置大小
                let clickedCoord = {}
                clickedCoord = {
                    x: selected.width - 1,
                    y: selected.height - 1
                }
                if (type !== 'add' && config) {
                    clickedCoord = {
                        x: config.w - 1,
                        y: config.h - 1
                    }
                }
                const views = disabledItems.filter(item => !item.disabled)
                const coordViews = views.filter(item => {
                    if (item.x <= clickedCoord.x && item.y <= clickedCoord.y) {
                        return item
                    }
                })
                const maxTarget = {
                    x: 0,
                    y: 0
                }
                coordViews.forEach(item => {
                    if (maxTarget.x + maxTarget.y < item.x + item.y) {
                        maxTarget.x = item.x
                        maxTarget.y = item.y
                    }
                })
                // todo:默认尺寸
                this.clickedCoord = maxTarget
                const box = {
                    w: this.clickedCoord.x + 1,
                    h: this.clickedCoord.y + 1
                }
                this.$emit('handel-box', box)
            }
        }
    },
    async created () {
        await gbDictionary.init(this, ['工作台小部件类型'])
        this.gridInit()
    },
    mounted () {
        setTimeout(() => {
            this.visible = true
        }, 500)
    },
    methods: {
        isRange (val) {
            const { x, y, i } = this.config.gridBox || {}
            const colTotal = this.colTotal
            const rowTotal = this.maxRowTotal
            const currentCol = this.currentCol
            const items = this.addItems.filter(item => {
                if (this.editType !== 'add') {
                    return item.i !== i && item.i !== 0
                } else {
                    return item.i !== 0
                }
            })
            const minX = Math.min(...items.map(item => item.x))
            const minXy = Math.min(
                ...items.filter(items => items.x >= minX).map(item => item.y)
            )
            const xPoint = {
                x: minX,
                y: minXy
            }
            const minY = Math.min(...items.map(item => item.y))
            const minYx = Math.min(
                ...items.filter(items => items.y >= minY).map(item => item.x)
            )
            const yPoint = {
                x: minYx,
                y: minY
            }
            const isRange = val =>
                (val.x + x >= xPoint.x && val.y + y >= xPoint.y) ||
                (val.x + x >= yPoint.x && val.y + y >= yPoint.y)
            return isRange
        },
        handleItemClick ({ x, y, disabled } = {}) {
            if (disabled) {
                return
            }
            this.clickedCoord = {
                x,
                y
            }
            const box = {
                w: x + 1,
                h: y + 1
            }
            this.$emit('handel-box', box)
        },
        handleMouseOver (item) {
            // 监听item鼠标移入
            if (!item.disabled) {
                this.hovered = item
            }
        },
        handleMouseLeave (item) {
            this.hovered = null
        },
        createDefaultLayout () {
            // 默认站位item
            const total = this.currentCol * this.currentMaxRowTotal
            let row = 0
            const items = []
            for (let i = 0; i < total; i++) {
                const x = i % this.currentCol
                items.push({
                    i,
                    x,
                    y: row,
                    w: 1,
                    h: 1,
                    disabled: false,
                    hovered: false
                })
                if (Math.floor((i + 1) / this.currentCol) !== row) {
                    row = Math.floor((i + 1) / this.currentCol)
                }
            }
            return items
        },
        gridInit () {
            this.defaultItems = this.createDefaultLayout()
        }
    }
}
</script>
<style lang="scss" scoped>
.edit-zone {
    height: 100%;
    overflow-y: auto;
}

.config-area {
    .component-name {
        margin: 0.17rem 0 0.33rem;
        font-size: 0.24rem;
        font-weight: 500;
        line-height: 0.24rem;
        color: #606266;
    }

    .size-title {
        // margin-bottom: 0.07rem;
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.2rem;
        color: #909399;
    }

    &-main {
        width: 2.36rem;
        min-width: 236px;
        margin-left: -10px;
        // background: #eee;
        .vue-grid-item {
            border: 2px solid #f0f0f0;
        }
    }

    .static-item {
        width: 100%;
        height: 100%;
        background: #fff;
        transition: all 0.3s;
    }

    .item-disabled {
        cursor: not-allowed;
        background: #f0f0f0;
    }

    .hovered {
        background: #69acf1;
    }

    .details {
        position: relative;
        margin-top: 0.28rem;

        .logo {
            position: absolute;
            top: 0;
            right: 0;
            width: 1rem;
            height: 1rem;
            border: 1px solid rgb(240 240 240 / 60%);
            border-radius: 4px;
            // background-color: #f0f0f0;
            // background-repeat: no-repeat;
            // background-position: center center;
            // background-size: cover;
        }

        .image-slot {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 0.3rem;
            color: #909399;
            background: #f5f7fa;
        }

        .p12 {
            padding-right: 1.2rem;
        }

        .item {
            display: flex;
            margin-bottom: 0.18rem;

            .info {
                flex: 1;
            }
        }

        .component-item {
            flex-direction: column;
        }

        .custom-image {
            width: 0.8rem;
            min-width: 0.8rem;
            height: 0.8rem;
        }

        .custom-image-info {
            display: flex;
            margin-top: 0.12rem;
            overflow-x: auto;

            .custom-image {
                margin-right: 0.2rem;
            }
        }
    }
}
</style>
