<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        :link="'/oa/estimate'"
        :filter="true"
        class="talent-kpi-card"
        @handleFilter="handleFilter"
    >
        <template v-slot:cardOptions>
            <gb-department
                ref="oaGroup"
                v-model="selectGroupId"
                style=" width: 0.8rem"
                class="oaGroup"
                placeholder="组织"
                api-name="getAllDepts"
                :disabled-ids="ownedDeptIds"
                :is-cascader="true"
                :show-all-levels="false"
                value-filed="id"
                :expand-trigger="'click'"
                label-filed="groupName"
                @change="handleDeptChange"
            >
            </gb-department>
        </template>
        <section>
            <gb-echart
                ref="kpi"
                config="kpi"
                height="100%"
                width="100%"
            />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import { moneyFilter } from '@/utils/index'
import gbDepartment from '@/components/Department'
import GbEchart from '@/components/Echart'
import ehr from '@/services/ehr'

export default {
    name: '',
    components: {
        CardComponent,
        gbDepartment,
        GbEchart
    },
    props: {
        groupId: {
            type: [Number, String],
            default: null
        }
    },
    data () {
        return {
            title: 'KPI考核',
            tip: '财务经营',
            icon: 'iconfont icon-huanxingtu',
            options: [
                {
                    label: '上周',
                    value: 1
                },
                {
                    label: '本周',
                    value: 0
                }
            ],
            currentUser: {},
            group: {},
            bussinessDepts: [],
            selectGroupId: 0,
            data: {},
            searchData: {},
            account: {
                oneScoreList: [],
                twoScoreList: [],
                threeScoreList: []
            }
        }
    },

    computed: {
        ownedDeptIds () {
            let arr = []
            arr.push(this.selectGroupId)
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    arr.push(item.groupId)
                })
            }
            arr = [...new Set(arr)]
            // console.log(arr)
            return arr
        }
    },
    mounted () {
        this.currentUser = this.$axios.getCurrentUser()
        this.getHomeData()
    },
    created () {},
    methods: {
        moneyFilter,
        handleFilter (val) {
            //   this.getData(val)
        },
        async getHomeData () {
            this.isLoading = true
            this.searchData.groupId = this.selectGroupId
            const res = await ehr.kpiestimateForKpi(this.searchData)
            console.log('res:' + JSON.stringify(res))
            this.init(this)
            this.isLoading = false
            let echart = this.$refs.kpi
            echart.option.series[0].data = this.assembleScore(res.data)
            echart.refresh()
        },

        init (data) {},

        handleDeptChange (val) {
            this.bussinessDepts = []
            let flag = true
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    if (item.isAdmin && flag) {
                        item.groupId = val.id
                        item.groupName = val.groupName
                        flag = false
                    } else {
                        item.isAdmin = false
                    }
                })
            } else {
                if (val) {
                    this.bussinessDepts.push({
                        groupId: val.id,
                        groupName: val.groupName,
                        isAdmin: true,
                        isPrimary: true,
                        isOwn: false
                    })
                }
            }

            this.getHomeData()
        },

        // 拼接数据
        assembleScore (data) {
            console.log('data:' + JSON.stringify(data))
            let oneScoreList = []
            let twoScoreList = []
            let threeScoreList = []
            let fourScoreList = []
            let fiveScoreList = []

            for (var i = 0; i < data.length; i++) {
                if (data[i].estimateValue >= 110) {
                    oneScoreList.push(data[i])
                }
                if (data[i].estimateValue >= 100 && data[i].estimateValue < 110) {
                    twoScoreList.push(data[i])
                }
                if (data[i].estimateValue >= 90 && data[i].estimateValue < 100) {
                    threeScoreList.push(data[i])
                }
                if (data[i].estimateValue >= 70 && data[i].estimateValue < 90) {
                    fourScoreList.push(data[i])
                }
                if (data[i].estimateValue < 70) {
                    fiveScoreList.push(data[i])
                }
            }

            this.account.oneScoreList = oneScoreList
            this.account.twoScoreList = twoScoreList
            this.account.threeScoreList = threeScoreList
            this.account.fourScoreList = fourScoreList
            this.account.fiveScoreList = fiveScoreList
            var data = []
            var json = { value: oneScoreList.length, name: 'P≥110' }
            data.push(json)
            json = { value: twoScoreList.length, name: '100≤P≤110' }
            data.push(json)
            json = { value: threeScoreList.length, name: '90≤P≤100' }
            data.push(json)
            json = { value: fourScoreList.length, name: '70≤P≤90' }
            data.push(json)
            json = { value: fiveScoreList.length, name: 'P<70' }
            data.push(json)
            console.log('最终值：' + JSON.stringify(data))
            return data
        }
    }
}
</script>
<style lang="less" scoped>
    /* stylelint-disable */
.talent-kpi-card {
  grid-row-start: span 2;
}
section {
  margin-top: 0.16rem;
  flex: 1;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-around;
  flex-direction: row;
  .title {
    height: 0.24rem;
    font-family: PingFangSC-Regular;
    font-size: 0.16rem;
    color: #a7b6c3;
    line-height: 0.24rem;
    font-weight: 400;
  }
  .gmv {
    height: 0.96rem;
    color: #54636f;
    margin-bottom: 0.36rem;
    .unit {
      font-size: 0.26rem;
      // font-size: 0.4rem;
    }
    .price {
      height: 0.7rem;
      font-family: SanFranciscoDisplay-Medium;
      // font-size: 0.5rem;
      font-size: 0.44rem;
      line-height: 0.7rem;
      font-weight: 500;
    }
  }
  .other {
    display: flex;
    // justify-content: space-between;
    color: #54636f;
    flex: 1;
    .unit {
      // font-size: 0.25rem;
      font-size: 0.2rem;
    }
    .price {
      height: 0.44rem;
      font-family: SanFranciscoDisplay-Medium;
      font-size: 0.24rem;
      line-height: 0.44rem;
      font-weight: 500;
    }
    .price.green {
      font-size: 0.24rem;
      color: #39a36b;
    }
    .price.red {
      font-size: 0.24rem;
      color: #df5a68;
    }

    div:nth-child(1) {
      width: 50%;
    }
    div:nth-child(2) {
      padding-left: 25px;
      flex: 1;
    }
  }
  .main-left {
    flex: 1;
    margin-right: 120px;
    #left-pie {
      width: 100%;
      height: 100%;
    }
  }
  .main-right {
    display: flex;
    align-items: center;
    margin-right: 100px;
    .grid-data {
      height: 254px;
      position: relative;
      display: grid;
      padding: 4px;
      // width: 470px;
      // height: 260px;
      border-left: 2px solid #c0c4cc;
      border-bottom: 2px solid #c0c4cc;
      grid-template-columns: 152px 152px 152px;
      grid-template-rows: 82px 82px 82px;
      grid-row-gap: 4px;
      grid-column-gap: 4px;
    }
    .grid-data-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #39a36b;
      line-height: 24px;
      font-weight: 500;
      border: 2px solid #3595e1;
      border-radius: 12px;
      box-sizing: border-box;
    }
    .grid-data-item-low {
      color: #39a36b;
      border: 2px solid #39a36b;
    }
    .grid-data-item-medium {
      color: #39a36b;
      border: 2px solid #39a36b;
    }
    .grid-data-item-high {
      color: #df5a68;
      border: 2px solid #df5a68;
    }
    .position-top,
    .position-bottom {
      position: absolute;
    }
    .position-top,
    .position-bottom {
      font-size: 14px;
      color: #a7b6c3;
      font-weight: 400;
    }
    .position-top {
      left: -110px;
      margin-top: -7px;
      text-align: right;
      width: 100px;
    }
    .top-text,
    .bottom-text {
      color: #606266;
      font-weight: 500;
    }
    .top-text {
      bottom: 100%;
      margin-bottom: -7px;
    }
    .high-rank {
      top: 83%;
    }
    .medium-rank {
      top: 50%;
    }
    .low-rank {
      top: 16%;
    }
    .position-bottom {
      bottom: -30px;
      width: auto;
      margin-left: -7px;
    }
    .bottom-text {
      left: 100%;
      width: 100px;
      margin-left: -20px;
    }
    .low-level {
      left: 16%;
    }
    .medium-level {
      left: 50%;
    }
    .high-level {
      left: 83%;
    }
    .custom-arrow-top,
    .custom-arrow-bottom {
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .custom-arrow-top {
      background-image: url(../../../assets/images/grid_arrow_top.png);
      width: 4px;
      height: 10px;
      left: -4px;
      top: -10px;
      margin-top: 0;
    }
    .custom-arrow-bottom {
      width: 10px;
      height: 4px;
      right: -10px;
      bottom: -2px;
      background-image: url(../../../assets/images/grid_arrow_right.png);
    }
  }
}
</style>
