<template>
    <div class="supply-chain desktop">
        <!-- 采购工作台 -->
        <group-tab :groups="groups" @groupChange="changeOrg"></group-tab>
        <el-scrollbar style="height: 100%;">
            <div class="supply-chain-main">
                <weatherSmall />
                <sumDataCard
                    card-title="出库货值"
                    :card-link="null"
                    card-icon="iconfont icon-chukuhuozhi"
                    class="sum2"
                    :has-select="false"
                    api-name="getStockCost"
                    :items="chhzItmes"
                    :fields="['thirtyDaysAmt', 'currentMonthAmt']"
                >
                </sumDataCard>
                <store-monitor ref="storeMonitor"> </store-monitor>
                <Pending class="item-pending" />
                <cash-medium
                    v-loading="chLoading"
                    title="存货金额"
                    :columns="chColumns"
                    :sum-data="chSumData"
                    :datas="chData"
                    icon="iconfont icon-cunhuojine"
                >
                    <template v-slot:cashOption>
                        <el-select
                            v-model="chParenterId"
                            style="width: 1.2rem;"
                            placeholder="请选择"
                            size="small"
                            @change="getStockMoney()"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </cash-medium>
                <value-percent></value-percent>
                <cash-medium
                    v-loading="ddLoading"
                    title="未发货订单"
                    :sum-data="unSendOrderSum"
                    :columns="unSendOrderColumns"
                    :datas="unSendOrderData"
                    icon="iconfont icon-weifahuodingdan"
                >
                </cash-medium>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import oaSvc from 'services/oa'
import groupTab from './components/orgs'
import contant from './cards/contant'
import weatherSmall from './cards/weatherSmall'
import sumDataCard from './cards/sumDataDisplay'
import cashMedium from './cards/cashMedium'
import Pending from './cards/pending'
import valuePercent from './cards/valuePercent'
import storeMonitor from './cards/storeMonitor'
import tradeSvc from 'services/trade'
import { makeDataToMap, assembleStatisticalData } from '@/utils/index'
import productSvc from 'services/product'
import financeSvc from 'services/finance'
export default {
    name: 'SupplyChainDesktop',
    components: {
        groupTab,
        weatherSmall,
        cashMedium,
        Pending,
        sumDataCard,
        valuePercent,
        storeMonitor
    },
    data () {
        return {
            contant,
            brands: [],
            groups: [],
            currentGroupInfo: {},
            chParenterId: 0,
            chColumns: ['品牌', '库存额', '在途额'],
            chSumData: [],
            chInfo: {},
            chData: [],
            chLoading: false,

            kcParenterId: null,
            unSendOrderSum: [],
            unSendOrderData: [],
            unSendOrderColumns: ['仓库', '未发货订单'],
            unSendOrderPartnerId: 0,
            ddLoading: false,

            // stockMonitorData: [], //库存监控数据
            brandId: 0,

            warehouseId: null,

            loading2: false,

            chhzItmes: ['近30天', '当月'],

            partners: [
                {
                    value: 0,
                    label: '全部'
                }
            ]
        }
    },
    created () {
        this.getUserGroups()
        this.getPartners()
        this.getBrands()
        // 存货金额
        this.getStockMoney()
        // 出库货值
        // this.getStockCost()
        // 未发货订单
        this.getUnSendOrder()
        // 库存监控及供应链日报多条件查询接口
        // this.getStockMonitor()
    },
    methods: {
        async getPartners () {
            try {
                const { data = [] } = await financeSvc.getPartners({ isOwn: 1 })
                let arr = data.map(item => {
                    let o = {}
                    o.value = item.id
                    o.label = item.partnerName
                    return o
                })
                this.partners.push(...arr)
            } catch (error) {
                console.log(error)
            }
        },
        async getBrands () {
            try {
                const { data = [] } = await productSvc.getBrands()
                data.unshift({
                    id: 0,
                    brandName: '全部'
                })
                this.brands = data
            } catch (error) {
                console.log(error)
            }
        },
        async getUserGroups () {
            const res = await oaSvc.getEmployeeInfo()
            this.groups = res.data.employeeGroupList
            this.currentGroupInfo = this.groups[0]
        },
        // 存货金额
        async getStockMoney () {
            try {
                this.chLoading = true
                const { data = {} } = await tradeSvc.getStockMoney(
                    this.chParenterId
                )
                this.chInfo = data
                this.chData = makeDataToMap(
                    data.brandStockAmountList,
                    'brandName',
                    {
                        valueField: 'stockAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    },
                    {
                        valueField: 'inRoadAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    }
                )
                this.chSumData = assembleStatisticalData(
                    data,
                    {
                        title: '存货总额',
                        valueField: 'stockTotalAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    },
                    {
                        title: '在途总额',
                        valueField: 'inRoadTotalAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7
                    }
                )
                this.chLoading = false
                console.log(this.chSumData, '=====chSumData')
            } catch (error) {
                console.log(error)
                this.chLoading = false
            }
        },
        // 未发货订单
        async getUnSendOrder () {
            try {
                this.ddLoading = true
                const { data = {} } = await tradeSvc.getUnSendOrder()
                this.unSendOrderSum = assembleStatisticalData(
                    data,
                    {
                        title: '未发货订单',
                        valueField: 'unSendOrderQuantity'
                    },
                    {
                        title: '待审核订单',
                        valueField: 'pendingReviewQuantity'
                    }
                )
                console.log(this.unSendOrderSum, '====unSendOrderSum')
                this.unSendOrderData = makeDataToMap(
                    data.unSendOrderList,
                    'wareHouseName',
                    {
                        valueField: 'unSendOrderQuantity',
                        isMoney: false
                    }
                )
                this.ddLoading = false
            } catch (error) {
                console.log(error)
                this.ddLoading = false
            }
        },
        changeOrg (index) {
            this.currentGroupInfo = this.groups[index]
        }
    }
}
</script>

<style lang="scss" scoped>
.supply-chain {
    &-main {
        box-sizing: border-box;
        display: grid;
        flex: 1;
        grid-template-rows: repeat(5, 2rem);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        width: 100%;
        padding: 0.2rem;

        .sum2 {
            grid-row-start: 2;
        }
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
}
</style>
