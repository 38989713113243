import request from '@/utils/request'

const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('financeServer', path, {
        params,
        ...restConfig
    })

export default {
    //                            _ooOoo_
    //                           o8888888o
    //                           88" . "88
    //                           (| -_- |)
    //                            O\ = /O
    //                        ____/'---'\____
    //                       .  ' \\| |// '  .
    //                       / \\||| : |||// \
    //                     / _||||| -:- |||||- \
    //                       | | \\\ - /// | |
    //                     | \_| ''\---/'' |_/  |
    //                      \ .-\__ `-` ___/-. /
    //                   ___`. .' /--.--\ '. .`___
    //                ."" '< '.___\_<|>_/___.' >' "".
    //               | | : `- `.;`\ _ /`;.`/ -` : | |
    //                 \ \ `-. \_ __\ /__ _/ .-` / /
    //         ======'-.____`-.___\_____/___.-`____.-'======
    //                            '=---='
    //         .............................................
    //                            财务工作台
    // -------------cash rest-------------
    getCashRestData (params) {
        // 获取现金余额数据
        return fetch('/finance/balance/table', params)
    },
    getCashByDate (balanceDate, partnerId = 0) {
        // 获取现金余额数据
        return fetch('/finance/balance/query', { balanceDate, partnerId })
    },
    addCashRest (params) {
        // 新增或者更新余额数据
        return fetch('/finance/balance/create', params)
    },
    getPartners (query) {
        // 获取公司
        return fetch('/finance/balance/partnerInfo/list', query)
    },
    // --------------end----------------
    // ----------------ys-------------
    getCheckReceiveList (partnerId) {
        // 获取核销应收列表
        return fetch('/finance/receivable/list', { partnerId })
    },
    saveCheckReceiveBill (params) {
        // 新增应收账单
        return fetch('/finance/receivable/save', params)
    },
    updateCheckReceiveBill (params) {
        // 修改应收账单
        return fetch('/finance/receivable/update', params)
    },
    updateStatus (params) {
        // 修改应收账单
        return fetch('/finance/receivable/updateStatus', params)
    },
    //  finance/receivable/updateStatus
    delCheckReceiveBill (id) {
        // 删除应收账单
        return fetch('/finance/receivable/delete', { id })
    },
    queryCheckReceiveBillById (id) {
        // 编辑回显查询
        return fetch('/finance/receivable/query', { id })
    },
    // ---------------end-------------------
    // ----------------yf-----------------
    getPaymentList (param) {
        // 获取yf列表
        return fetch('/finance/payment', param)
    }
}
