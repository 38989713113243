<template>
    <el-drawer
        title="我是标题"
        :visible.sync="visible"
        direction="btt"
        :show-close="false"
        :close-on-press-escape="false"
        :modal="false"
        :size="height"
        :with-header="false"
        :wrapper-closable="false"
    >
        <div>
            <el-button @click="handleHeight">展开</el-button>
            <el-button @click="handleHeight">隐藏</el-button>
        </div>
        <span>我来啦!</span>
        <div
            class="droppable-element"
            draggable="true"
            unselectable="on"
            @drag="drag"
            @dragend="dragend"
        >
            Droppable Element (Drag me!)
        </div>
    </el-drawer>
</template>
<script>
let mouseXY = { 'x': null, 'y': null }
let DragPos = { 'x': null, 'y': null, 'w': 1, 'h': 1, 'i': null }

export default {
    name: 'AddDrawerBottom',
    data () {
        return {
            visible: true,
            direction: 'btt',
            height: '10%'
        }
    },
    mounted () {
        document.addEventListener('dragover', function (e) {
            mouseXY.x = e.clientX
            mouseXY.y = e.clientY
        }, false)
    },
    methods: {
        handleHeight (e) {
            this.height = this.height === '10%' ? '90%' : '10%'
        },
        drag (e) {
            this.height = '10%'
            this.visible = false
            // let parentRect = document.getElementById('content').getBoundingClientRect()
            // let mouseInGrid = false
            // if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
            //     mouseInGrid = true
            // }
            // if (mouseInGrid === true) {
            //     this.layout.push({
            //         x: (this.layout.length * 2) % (this.colNum || 12),
            //         y: this.layout.length + (this.colNum || 12), // puts it at the bottom
            //         w: 1,
            //         h: 1,
            //         i: this.layout.length + 1
            //     })
            // }
            this.$emit('drag')
            // let index = this.layout.findIndex(item => item.i === 'drop')
            // if (index !== -1) {
            //     try {
            //         this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'none'
            //     } catch {
            //     }
            //     let el = this.$refs.gridlayout.$children[index]
            //     el.dragging = { 'top': mouseXY.y - parentRect.top, 'left': mouseXY.x - parentRect.left }
            //     let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
            //     if (mouseInGrid === true) {
            //         this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1)
            //         DragPos.i = String(index)
            //         DragPos.x = this.layout[index].x
            //         DragPos.y = this.layout[index].y
            //     }
            //     if (mouseInGrid === false) {
            //         this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1)
            //         this.layout = this.layout.filter(obj => obj.i !== 'drop')
            //     }
            // }
        },
        dragend (e) {
            // let parentRect = document.getElementById('content').getBoundingClientRect()
            // let mouseInGrid = false
            // if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
            //     mouseInGrid = true
            // }
            // if (mouseInGrid === true) {
            //     this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1)
            //     // this.layout = this.layout.filter(obj => obj.i !== 'drop')
            // }
            this.$emit('drag-end')
            this.height = '10%'
            this.visible = true
        }
    }
}
</script>
