<template>
    <div class="default desktop">
        <p class="welcome">{{ currentUser.displayName }}，欢迎使用骨贝平台</p>
        <el-scrollbar style="flex:1">
            <div class="content">
                <CalendarCard />
                <Weather />
                <!-- </div> -->
                <!-- <div class="item"> -->
                <Train class="item3" />
                <Pending />
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Pending from './cards/pending'
import Train from './cards/train'
import Weather from './cards/weather'
import CalendarCard from './cards/calendar'
export default {
    name: 'DefaultDesktop',
    components: { Pending, Train, Weather, CalendarCard },
    data() {
        return {
            currentUser: {}
        }
    },
    created() {
        this.currentUser = this.$axios.getCurrentUser()
    }
}
</script>

<style lang="less" scoped>
.default {
    height: 100%;
    background: url('../../assets/images/default.jpg') no-repeat;
    background-size: cover;
    padding: 0;
    .welcome {
        font-size: 0.3rem;
        color: #fff;
        font-weight: 400;
        padding-left: 0.2rem;
        padding-top: 0.2rem;
    }
    .content {
        margin-top: 0.2rem;
        padding: 0.2rem;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-rows: repeat(4, 200px);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        .item3 {
            /deep/ .main {
                .training {
                    padding-left: 0;
                }
                .training .score {
                    width: 100%;
                    height: 3.2rem;
                }
                .training .score .season {
                    padding-top: 0.3rem;
                    padding-bottom: 0.64rem;
                }
                .training .score .progress {
                    .tag {
                        top: -0.44rem;
                        font-size: 0.18rem;
                    }
                    .el-progress-bar__outer {
                        height: 0.24rem !important;
                        background-color: #e4eaef;
                    }
                }
                .training .score .season span {
                    display: flex;
                    flex-direction: column;
                    b {
                        font-size: 0.48rem;
                        color: #3595e1;
                        line-height: 60px;
                        font-weight: 400;
                    }
                }
                .training .score .season .date {
                    font-size: 0.18rem;
                }
                .training .score p {
                    font-size: 0.18rem !important;
                }
            }
        }
    }
}
</style>
