// var labels = ['1/3', '1/3', '1/3', '1/3', '1/3', '1/3', '1/3']
export const option = {
    labels: [],
    color: ['#69ACF1', '#54636F'],
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: '{b0}<br />{a0}: {c0}万 <br />{a1}: {c1}万'
    },
    legend: {
        data: [ '正品货值', '残次货值'],
        bottom: '0'
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '9%',
        top: '6%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
            interval: 0,
            color: '#54636F',
            formatter: function (value) {
                return value + '万'
            }
        }
    },
    yAxis: {
        type: 'category',
        data: [],
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
            interval: 0,
            color: '#54636F',
            formatter: function (value) {
                return value.length > 11 ? value.substr(0, 11) + '...' : value
            }
        }
    },
    series: [
        {
            barWidth: '20',
            name: '正品货值',
            type: 'bar',
            stack: 'total',
            emphasis: {
                focus: 'series'
            },
            // label: {
            //     show: true,
            //     position: 'right', //在上方显示
            //     fontSize: '12',
            //     color: '#333',
            //     formatter: function(params) {
            //         return option.labels[params.dataIndex]
            //     }
            // },
            data: []
        },
        {
            name: '残次货值',
            type: 'bar',
            stack: 'total',
            label: {
                show: false,
                position: 'right', // 在上方显示
                fontSize: '12',
                color: '#333',
                formatter: function (params) {
                    return option.labels[params.dataIndex]
                }
            },
            emphasis: {
                focus: 'series'
            },
            data: []
        }
    ]
}
