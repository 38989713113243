<template>
    <div>
        <el-dialog
            class="day-plan-dialog"
            :title="title"
            top="2.8vh"
            :visible.sync="visible"
            :lock-scroll="false"
            width="1000px"
            :close-on-click-modal="false"
            :append-to-body="true"
        >
            <p class="welcome">
                {{ timeText }}好！看看今日有哪些计划要做的事叭~
            </p>
            <div class="day-plan-dialog-main">
                <div class="left">
                    <div class="content">
                        <div class="title">
                            <!-- 今日待办({{ finishCount }}/{{ totalCount }}) -->
                            今日待办
                        </div>

                        <AddTodoInput ref="AddTodoInput" :is-add="true" style="margin-bottom: 0.15rem;" />
                        <!-- 1 -->
                        <draggable
                            v-model="stickPlans"
                            element="ul"
                            group="group1"
                            animation="300"
                            class="plans stick"
                            drag-class="dragClass"
                            ghost-class="ghostClass"
                            chosen-class="chosenClass"
                        >
                            <transition-group>
                                <li
                                    v-for="(item, notIndex) in stickPlans"
                                    :key="`${item.title + notIndex}`"
                                >
                                    <i class="iconfont icon-zhidingjiaobiao"></i>
                                    <el-button
                                        title="取消置顶"
                                        class="icon-btn"
                                        size="mini"
                                        icon="iconfont icon-jinrijihua-quxiaozhiding"
                                        circle
                                        @click="cancelTop(item)"
                                    ></el-button>

                                    <!-- <addTodoPopover
                                        :ref="`editTodoPopover${item.id}`"
                                        :is-edit="true"
                                        @transform-data="
                                            transformData(item, notIndex)
                                        "
                                        @refresh-popover="refreshPopover"
                                    /> -->
                                    <div class="li-content">
                                        <!-- <el-tooltip
                                            v-if="item.title.length > 15"
                                            class="item"
                                            effect="dark"
                                            :content="item.title"
                                            placement="top"
                                        >
                                            <p style="font-weight: 500;">
                                                {{ item.title }}
                                            </p>
                                        </el-tooltip>

                                        <p v-else style="font-weight: 500;">
                                            {{ item.title }}
                                        </p> -->
                                        <TodoEditPopover :is-edit="true" :title="item.title" :todo-id="item.id" />
                                        <p>
                                            <!-- {{
                                                moment(
                                                    item.scheduleStart
                                                ).format('YYYY.MM.DD')
                                            }}-{{
                                                moment(item.scheduleEnd).format(
                                                    'YYYY.MM.DD'
                                                )
                                            }} -->
                                            {{ getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]) }}
                                        </p>
                                    </div>
                                    <div class="li-tags">
                                        <span
                                            :class="{
                                                high: item.priority === 30,
                                                mid: item.priority === 20,
                                                low: item.priority === 10
                                            }"
                                        >{{
                                            item.priority | priorityFilter
                                        }}</span>
                                        <span v-if="item.delay" class="delay">
                                            延期
                                        </span>
                                        <span>{{
                                            dicFilter(
                                                null,
                                                null,
                                                item.status,
                                                null,
                                                provideObj.todoStatusDicts
                                            )
                                        }}</span>
                                    </div>
                                </li>
                            </transition-group>
                        </draggable>
                        <!-- 2 -->
                        <draggable
                            v-model="tempTops"
                            element="ul"
                            :group="group2Name"
                            disabled
                            class="plans stick"
                        >
                            <li
                                v-for="item in stickCount"
                                :key="item"
                                class="null item"
                            >
                                <span class="empty">
                                    <i class="el-icon-upload2"></i>
                                    下方待办可拖入置顶
                                </span>
                            </li>
                        </draggable>
                        <!-- 3 -->
                        <draggable
                            v-model="notStickPlans"
                            group="stick"
                            element="ul"
                            animation="100"
                            class="plans not-stick"
                            drag-class="dragClass"
                            ghost-class="ghostClass"
                            chosen-class="chosenClass"
                            @end="onEnd"
                        >
                            <transition-group>
                                <li
                                    v-for="(item, notIndex) in notStickPlans"
                                    :key="item.title"
                                >
                                    <!-- <span
                                        class="icon"
                                        @click="removeToRight(item)"
                                    >
                                        <i class="el-icon-remove-outline"></i>
                                    </span>
                                     -->
                                    <el-button
                                        class="icon-btn"
                                        size="mini"
                                        icon="iconfont icon-jinrijihua-jianhao"
                                        circle
                                        @click="removeToRight(item)"
                                    ></el-button>
                                    <!-- <addTodoPopover
                                        :ref="`editTodoPopover${item.id}`"
                                        :is-edit="true"
                                        @transform-data="
                                            transformData(item, notIndex)
                                        "
                                        @refresh-popover="refreshPopover"
                                    /> -->
                                    <div class="li-content">
                                        <!-- <el-tooltip
                                            v-if="item.title.length > 15"
                                            class="item"
                                            effect="dark"
                                            :content="item.title"
                                            placement="top"
                                        >
                                            <p>{{ item.title }}</p>
                                        </el-tooltip>
                                        <p v-else>{{ item.title }}</p> -->
                                        <TodoEditPopover :is-edit="true" :title="item.title" :todo-id="item.id" />

                                        <p>
                                            {{ getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]) }}
                                        </p>
                                    </div>
                                    <div class="li-tags">
                                        <span
                                            :class="{
                                                high: item.priority === 30,
                                                mid: item.priority === 20,
                                                low: item.priority === 10
                                            }"
                                        >{{
                                            item.priority | priorityFilter
                                        }}</span>
                                        <span v-if="item.delay" class="delay">
                                            延期
                                        </span>
                                        <span>{{
                                            dicFilter(
                                                null,
                                                null,
                                                item.status,
                                                null,
                                                provideObj.todoStatusDicts
                                            )
                                        }}</span>
                                    </div>
                                    <!-- <span class="editIcon">
                                        <i class="iconfont icon-bianji"></i>
                                    </span> -->
                                </li>
                            </transition-group>
                        </draggable>
                        <!-- 4 -->
                        <draggable
                            element="ul"
                            :group="stickCount === 7 ? 'stick' : 'not-stick'"
                            disabled
                            class="plans not-stick"
                        >
                            <!-- <ul class="plans not-stick"> -->
                            <li
                                v-for="(item, itemIndex) in notStickCount"
                                :key="item"
                                class="null"
                            >
                                <span
                                    v-if="itemIndex === notStickCount - 1"
                                    class="empty"
                                >
                                    <!-- <i class="el-icon-back"></i> -->
                                    "+" 加入今日待办，"-"
                                    移除，上下拖动调整待办顺序
                                </span>
                            </li>
                            <!-- </ul> -->
                        </draggable>
                    </div>
                    <div class="bottom">
                        <el-button
                            type="primary"
                            @click="visible = false"
                        >
                            设置完毕 开始工作
                        </el-button>
                    </div>
                </div>
                <div class="right">
                    <div class="tabs">
                        <span
                            v-for="(tab, index) in tabs"
                            :key="index"
                            :class="{ actived: iscur === index }"
                            @click="changeTab(index)"
                        >{{ tab }}</span>
                    </div>
                    <div class="content">
                        <div class="search">
                            <el-input
                                v-model="form.titleLike"
                                placeholder="搜索待办事项"
                                clearable
                                @input="queryData"
                                @clear="queryData"
                            ></el-input>
                            <el-date-picker
                                v-model="form.dateRange"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                clearable
                                @change="queryData"
                            >
                            </el-date-picker>
                            <!-- <div class="add-icon" @click="showAdd">
                                <addTodoPopover
                                    ref="addTodoPopover"
                                    @refresh-popover="refreshPopover"
                                />
                            </div> -->
                        </div>
                        <ul class="plans">
                            <li
                                v-for="(item, notIndex) in todoList"
                                :key="item.id"
                            >
                                <!-- <span class="icon" @click="addToLeft(item)">
                                    <i class="el-icon-circle-plus-outline"></i>
                                </span> -->
                                <el-button
                                    class="icon-btn"
                                    size="mini"
                                    icon="iconfont icon-jinrijihua-jiahao"
                                    circle
                                    @click="addToLeft(item)"
                                ></el-button>
                                <!-- <addTodoPopover
                                    :ref="`editTodoPopover${item.id}`"
                                    :is-edit="true"
                                    @transform-data="
                                        transformData(item, notIndex)
                                    "
                                    @refresh-popover="refreshPopover"
                                /> -->
                                <div class="li-content">
                                    <!-- <el-tooltip
                                        v-if="item.title.length > 15"
                                        class="item"
                                        effect="dark"
                                        :content="item.title"
                                        placement="top"
                                    >
                                        <p>{{ item.title }}</p>
                                    </el-tooltip>
                                    <p v-else>{{ item.title }}</p> -->
                                    <TodoEditPopover :is-edit="true" :title="item.title" :todo-id="item.id" />
                                    <p>
                                        {{ getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]) }}
                                    </p>
                                </div>
                                <div class="li-tags">
                                    <span
                                        :class="{
                                            high: item.priority === 30,
                                            mid: item.priority === 20,
                                            low: item.priority === 10
                                        }"
                                    >{{
                                        item.priority | priorityFilter
                                    }}</span>
                                    <span v-if="item.delay" class="delay">
                                        延期
                                    </span>
                                    <span>{{
                                        dicFilter(
                                            null,
                                            null,
                                            item.status,
                                            null,
                                            provideObj.todoStatusDicts
                                        )
                                    }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="bottom">
                        <el-pagination
                            small
                            layout="total,prev, pager, next"
                            :total="total"
                            :page-size="9"
                            :current-page.sync="form.current"
                            @current-change="handleCurrentChange"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'
import bus from 'utils/eventBus'
import addTodoPopover from '@/modules/todo/dayPlan/components/addTodoPopover'
import todoSvc from 'services/todo'
import tableMethod from 'utils/table'
import moment from 'moment'
import { getMeridiem } from 'utils/date'
import { getDateRangeDisplay } from 'utils/date'
import AddTodoInput from '@/modules/todo/pending/components/addTodo'
import TodoEditPopover from 'modules/todo/pending/components/todoEditPopover'
export default {
    name: 'DayPlanDialog',
    components: { addTodoPopover, AddTodoInput, TodoEditPopover },
    filters: {
        priorityFilter: value => {
            switch (value) {
            case 10:
                return '低'
            case 20:
                return '中'
            case 30:
                return '高'
            }
        }
    },
    inject: ['provideObj'],
    data () {
        return {
            visible: false,
            title: '',
            // 置顶数据
            stickPlans: [],
            tempTops: [],
            // 左侧未置顶列表
            notStickPlans: [],
            // 右侧列表
            todoList: [],
            flag: true,
            // tabs: ['待办清单', '历史待办'],
            tabs: ['待办清单'],
            iscur: 0,
            group2Name: 'stick',
            form: {
                size: 9,
                current: 1,
                dateRange: []
            },
            total: 0,
            timeText: ''
            // loading: false
        }
    },
    computed: {
        stickCount () {
            return 3 - this.stickPlans.length < 0
                ? 0
                : 3 - this.stickPlans.length
        },
        notStickCount () {
            return 7 - this.notStickPlans.length < 0
                ? 0
                : 7 - this.notStickPlans.length
        },
        totalCount () {
            return this.stickPlans.length + this.notStickPlans.length
        },
        finishCount () {
            return (
                this.stickPlans.filter(item => item.status === 1).length +
                this.notStickPlans.filter(item => item.status === 1).length
            )
        }
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                this.timeText = getMeridiem()
                this.iscur = 0
                this.$nextTick(() => {
                    this.$refs.AddTodoInput.more = false
                    this.$refs.AddTodoInput.tempTitle = ''
                    this.$refs.AddTodoInput.clearInputvalue()
                })
                this.getAllTodoList()
                this.refreshTopList()
                this.refreshNotTopList()
            } else {
                this.$emit('handle-refresh')
            }
        },
        stickPlans: {
            immediate: true,
            deep: true,
            async handler (newValue, oldValue) {
                if (newValue.length === 3) {
                    this.group2Name = 'twoFull'
                    // this.tempTops = []
                } else {
                    this.group2Name = 'stick'
                }
                await todoSvc.topAndNotTopSort(newValue)
            }
        },
        notStickPlans: {
            immediate: true,
            deep: true,
            async handler (newValue) {
                await todoSvc.topAndNotTopSort(newValue)
            }
        },
        iscur (val) {
            this.form = { size: 9, current: 1, dateRange: [] }
            this.getAllTodoList()
        }
    },
    mounted () {
        bus.$on('refresh-all-todo-list', this.refreshPopover)
    },
    created () {
        const currentDate = this.$moment().format('YYYY.MM.DD')
        const weekDay = this.getWeek()
        this.title = currentDate + ' ' + weekDay
        // this.getAllTodoList()
    },
    methods: {
        getDateRangeDisplay,
        ...tableMethod,
        moment,
        handleCurrentChange (val) {
            this.form.current = val
            this.getAllTodoList()
        },
        showAdd () {
            // const _ref = this.$refs.addTodoDailog
            // _ref.isEdit = false
            // _ref.title = '新建待办'
            // _ref.visible = true
        },
        async getAllTodoList () {
            if (this.form.dateRange && this.form.dateRange.length > 0) {
                this.form.scheduleFrom = this.form.dateRange[0]
                this.form.scheduleTo = this.form.dateRange[1]
            } else {
                this.form.scheduleFrom = null
                this.form.scheduleTo = null
            }
            let apiName = !this.iscur ? 'getAllTodoList' : 'getHisTodoList'
            // this.loading = true
            const res = await todoSvc[apiName](this.form)
            // this.loading = false
            this.markDelay(res.data)
            this.todoList = res.data
            this.total = res.total
        },
        handleConfirm () {},
        handleCancel () {
            this.visible = false
        },
        markDelay (arr) {
            arr.forEach(item => {
                const scheduleEnd = this.$moment(item.scheduleEnd)
                const current = this.$moment()
                const diff = scheduleEnd.diff(current, 'days')
                if (item.status === 0 && diff < 0) {
                    item.delay = true
                }
            })
        },
        async onEnd (e) {
            const res = _.xorBy(this.tempTops, this.stickPlans, 'id')
            if (res && res.length > 0) {
                // 移入置顶
                await todoSvc.moveToTop({
                    todoId: res[0].id
                })
                this.refreshTopList()
                this.refreshNotTopList()
            } else {
                // 内部排序
            }
        },
        changeTab (index) {
            this.iscur = index
        },
        // 取消置顶
        async cancelTop (item) {
            // this.notStickPlans.unshift(item)
            // this.stickPlans.forEach((o, index) => {
            //     if (o.id === item.id) {
            //         this.stickPlans.splice(index, 1)
            //     }
            // })
            // this.tempTops = _.cloneDeep(this.stickPlans)
            await todoSvc.moveFormTopToNotTop({
                todoId: item.id
            })
            this.refreshTodoList()
            this.refreshTopList()
            this.refreshNotTopList()
        },
        getWeek () {
            // 参数时间戳
            let week = this.$moment().day()
            switch (week) {
            case 1:
                return '周一'
            case 2:
                return '周二'
            case 3:
                return '周三'
            case 4:
                return '周四'
            case 5:
                return '周五'
            case 6:
                return '周六'
            case 0:
                return '周日'
            }
        },
        async addToLeft (item) {
            // this.notStickPlans.push(item)
            // this.todoList.splice(this.findIndex(this.todoList, item, 'id'), 1)
            let apiName = this.iscur ? 'hisMoveToNotTop' : 'removeToNotTop'
            await todoSvc[apiName]([
                {
                    id: item.id
                }
            ])
            this.refreshTodoList()
            this.refreshNotTopList()
        },
        async removeToRight (item) {
            // this.todoList.push(item)
            // this.notStickPlans.splice(
            //     this.findIndex(this.notStickPlans, item, 'id'),
            //     1
            // )
            await todoSvc.moveFormNotTopToList([
                {
                    id: item.id
                }
            ])
            this.refreshTodoList()
            this.refreshNotTopList()
        },
        queryData () {
            this.form.current = 1
            this.getAllTodoList()
        },
        findIndex (arr, target, key) {
            let idx = -1
            arr.forEach((item, index) => {
                if (item[key] === target[key]) {
                    idx = index
                }
            })
            return idx
        },
        refreshTodoList () {
            this.form.current = 1
            this.getAllTodoList()
        },
        async refreshTopList () {
            const { data = [] } = await todoSvc.todoTop()
            this.tempTops = this.stickPlans = data
            this.markDelay(this.stickPlans)
        },
        async refreshNotTopList () {
            const { data = [] } = await todoSvc.todoNotTop()
            this.notStickPlans = data
            this.markDelay(this.notStickPlans)
        },
        refreshPopover () {
            this.getAllTodoList()
            this.refreshTopList()
            this.refreshNotTopList()
        },
        transformData (item, index) {
            const _ref = this.$refs[`editTodoPopover${item.id}`][0]
            if (_ref) {
                _ref.dialogVisible = false
                _ref.form = _.cloneDeep(item)
                this.$set(_ref.form, 'dateRange', [
                    this.$moment(item.scheduleStart).format('YYYY-MM-DD'),
                    this.$moment(item.scheduleEnd).format('YYYY-MM-DD')
                ])
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    display: flex;
    flex-direction: column;
    // height: 860px;
    .el-dialog__title {
        font-size: 18px;
        color: #606266;
    }

    .el-dialog__body {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0 20px;
    }

    .welcome {
        font-size: 30px;
        font-weight: 400;
        color: #69acf1;
    }

    .day-plan-dialog-main {
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 14px 0;

        > div {
            // width: 420px;
            height: 100%;
        }

        .left,
        .right {
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow: hidden;

            .content {
                box-sizing: border-box;
                flex: 1;
                padding: 0 20px;
            }

            .bottom {
                width: 100%;
                height: 42px;
                margin-top: 14px;

                .el-button {
                    height: 100%;
                    background: #69acf1;
                    border: none;
                    border-radius: 12px;
                }
            }

            .plans {
                li {
                    position: relative;
                    box-sizing: border-box;
                    display: flex;
                    // flex-direction: column;
                    // justify-content: center;
                    align-items: center;
                    justify-content: space-between;
                    height: 62px;
                    // padding-left: 10px;
                    border-bottom: 1px solid #f0f0f0;

                    .icon-btn.el-button {
                        padding: 4px;
                        margin-right: 5px;
                        background: #f0f0f0;
                        border: none;

                        .iconfont {
                            font-size: 12px;
                            color: #909399;
                            transform: scale(0.88);
                        }
                    }

                    .icon-btn.el-button:hover {
                        background: #909399;

                        .iconfont {
                            color: #fff;
                        }
                    }

                    .li-content {
                        flex: 1;
                        margin-left: 8px;
                        overflow: hidden;

                        p:nth-child(1) {
                            overflow: hidden;
                            font-size: 16px;
                            font-weight: 400;
                            color: #606266;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        p:nth-child(2) {
                            font-size: 14px;
                            font-weight: 400;
                            color: #c0c4cc;
                        }
                    }

                    .editIcon {
                        position: absolute;
                        top: 50%;
                        right: -10px;
                        transform: translateY(-50%);
                    }
                }

                li.null {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .li-tags {
                    span {
                        box-sizing: border-box;
                        display: inline-block;
                        height: 20px;
                        padding: 3px;
                        font-size: 12px;
                        line-height: 14px;
                        color: #fff;
                        text-align: center;
                        background-color: #80a3db;
                        border-radius: 4px;
                        transform: scale(0.9);
                    }

                    span.high,
                    span.delay {
                        background-color: #df5a68;
                    }

                    span.mid {
                        background-color: #e6a23c;
                    }

                    span + span {
                        margin-left: 4px;
                    }
                }
            }
        }

        .left {
            .content {
                max-height: 664px;
                overflow: auto;
                border: 4px solid #f0f0f0;
                border-radius: 40px;
                // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            }

            .content .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 44px;
                color: #606266;
            }

            .content .plans {
                .empty {
                    font-size: 14px;
                    color: #c0c4cc;
                }
            }

            .content .plans.stick {
                .icon-zhidingjiaobiao {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: rgb(223, 90, 104);
                }
            }
        }

        .right {
            // background-color: red;
            height: 720px;
            margin-left: 20px;

            .content {
                width: 100%;
                padding-left: 0;
            }

            .search {
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;

                .el-input,
                .el-input > input {
                    width: 160px;
                    border-radius: 0;
                }

                .el-input__icon.el-range__icon.el-icon-date {
                    display: none;
                }

                .el-range-editor {
                    width: 240px;
                    margin-right: 5px;
                    margin-left: 15px;
                    border-radius: 0;
                }

                .add-icon {
                    flex: 1;
                    font-size: 20px;
                    text-align: right;
                    cursor: pointer;
                }
            }

            .tabs {
                width: 100%;
                height: 44px;
                font-size: 16px;
                font-weight: 400;
                color: #909399;

                span {
                    display: inline-block;
                    height: 32px;
                    line-height: 32px;
                    cursor: pointer;
                }

                span.actived {
                    font-weight: 500;
                    color: #606266;
                    border-bottom: 2px solid #606266;
                }

                span + span {
                    margin-left: 20px;
                }
            }

            .bottom {
                text-align: right;
            }
        }
    }
}
</style>
