var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"todo-assign"},[_c('el-page-header',{attrs:{"title":_vm.pageTitle},on:{"back":_vm.goBack}},[_c('template',{slot:"content"})],2),_c('el-collapse',{attrs:{"accordion":""},on:{"change":_vm.handleChange},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.types),function(type,index){return _c('el-collapse-item',{key:type,attrs:{"title":type,"name":type}},[_c('draggle-list',{attrs:{"list":index === 0
                        ? _vm.followUp
                        : index === 2
                            ? _vm.assignHistory
                            : _vm.assignToday,"group-name":"groupName","disabled":true},scopedSlots:_vm._u([{key:"default",fn:function(item){return [(_vm.pageTitle === '我指派的')?_c('todo-item',{attrs:{"sign":"指派","right-dropdown-handles":index === 0
                                ? _vm.rightDropdownHandles
                                : index === 1
                                    ? _vm.handles
                                    : null,"item-data":item.data,"is-edit":index === 0 || index === 1,"is-detail":index === 2}}):_vm._e(),(_vm.pageTitle === '我参与的')?_c('todo-item',{attrs:{"sign":"指派","right-dropdown-handles":null,"item-data":item.data,"is-detail":true}}):_vm._e()]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }