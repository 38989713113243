<template>
    <CardComponent class="analysis-card" :title="title" :icon="icon">
        <template v-slot:cardOptions>
            <Query :is-mini="true" @handle-search="handleSearch" />
        </template>
        <template v-slot:cardHeader>
            <comments :form="form"></comments>
            <i class="iconfont icon-shujushangchuan" @click="handleRouter('OperationUpload')"></i>
            <i class="iconfont icon-tiaozhuan" @click="handleRouter('operation-chart')"></i>
        </template>
        <template v-if="form.spuId">
            <Analysis
                ref="analysis"
                class="mt20"
                :has-query="false"
                :form="form"
            />
        </template>
        <template v-else>
            <div class="empty-data">
                请选择商品。如未关注商品，请点击右上角商品快捷入口跳转<span
                    class="link"
                    @click="handleRouter('customer-goods')"
                >商品页</span>，添加关注
            </div>
        </template>
    </CardComponent>
</template>
<script>
import { mapMutations } from 'vuex'
import customerSvc from 'services/customer'
import CardComponent from '../components/card'
import Analysis from 'modules/operation/chart/components/charts/analysis'
import Query from 'modules/operation/chart/components/query'
import comments from './analysis/comments'

export default {
    name: 'AnalysisCard',
    components: {
        CardComponent,
        Analysis,
        Query,
        comments
    },
    beforeRouteLeave (to, from, next) {
        this.setChannels(null)
        next()
    },
    data () {
        return {
            title: '竞品对比分析',
            icon: 'iconfont icon-zhuzhuangtu',
            form: {
                spuId: null,
                startDay: null,
                endDay: null
            }
        }
    },
    methods: {
        handleRouter (name) {
            this.$router.push({
                name
            })
        },
        handleSearch (form) {
            Object.keys(this.form).forEach(item => {
                this.form[item] = form[item]
            })
            this.$nextTick(item => {
                if (!this.form.spuId) {
                    return
                }
                try {
                    this.getCompetingData()
                } catch (e) {
                    console.log(e)
                }
            })
        },
        getCompetingData () {
            // 更新数据
            this.$refs.analysis.getAllData()
        },
        getAllData () {
            try {
                this.getChannels()
            } catch (e) {
                console.log(e)
            }
        },
        async getChannels () {
            // 获取渠道列表
            const query = {
                enabled: true,
                foeFlag: false
            }
            const { data = [] } = await customerSvc.getChannels(query)
            this.setChannels(data)
        },
        ...mapMutations('customer', {
            setChannels: 'UPDATE_CHANNELS'
            // setSpuList: 'UPDATE_SPULIST',
        })
    },
    mounted () {
        // 触发缓存
        this.getAllData()
    },
    activated () {
        // 触发缓存
        this.getAllData()
    }
}
</script>
<style lang="scss" scoped>
.empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #606266;

    .link {
        color: #409eff;
        cursor: pointer;
    }
}

.mt20 {
    margin-top: 20px;
}

.analysis-card {
    ::v-deep header .left .title {
        min-width: 120px;
    }
}
</style>
