export const option = {
  grid: {
    left: '10',
    right: '10',
    bottom:'10',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: [],
    axisTick: { show: false },
    axisLine: { show: false },
    splitLine: { show: false },
  },
  yAxis: {
    type: 'value',
    axisTick: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    splitLine: { show: false },
  },
  series: [
    {
      data: [],
      type: 'bar',
      barWidth: '20',
      itemStyle: {
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [3],
          color: function(params) {
            //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
            var colorList = [
              '#3494e0',
              '#429ce3',
              '#50a2e6',
              '#5da9e9',
              '#6ab0ec',
              '#77b7ef',
              '#84bef1',
              '#91c4f4',
              '#9fccf7',
              '#abd2fa',
            ];
            return colorList[params.dataIndex];
          },
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            textStyle: {
              //数值样式
              color: '#3595E1',
              fontSize: 16,
            },
          },
        },
      },
    },
  ],
};
