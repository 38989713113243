export const option = {
    title: {
        // text: '销售额（元）',
        text: '销量',
        // left: '2%',
        top: '4%',
        textStyle: {
            color: '#A7B6C3',
            fontSize: 14,
            fontWeight: 400
        }
    },
    grid: {
        // top: '12px',
        left: '12px',
        right: '12px',
        // bottom: '12px',
        containLabel: true
    },
    xAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        type: 'category',
        data: [],
        axisLabel: {
            show: true,
            textStyle: {
                color: '#A7B6C3'
            }
        }
    },
    yAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        type: 'value',
        splitLine: { show: false },
        splitArea: {
            show: true,
            areaStyle: {
                color: ['#EDF1F4', 'transparent']
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#A7B6C3'
            }
        }
    },
    legend: {
        // data: ['消毒柜AAS', '展示柜LSC-310F（风直冷）','展示柜LSC-f','的花费多少','展示柜风直冷'],
        data: [],
        bottom: '0%',
        itemGap: 20,
        icon: 'circle'
    },
    tooltip: {
        trigger: 'axis'
    },
    series: []
}
