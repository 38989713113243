<template>
    <div class="core-focus">
        <p class="group">下属</p>
        <ul class="group-items">
            <li v-for="item in subordinate" :key="item.employeeId">
                <p class="item-title">
                    <span>{{ item.employeeName }}</span>
                    <span
                        @click="getSomeoneTodoList(item)"
                    ><i class="iconfont icon-bianji"></i></span>
                </p>
                <template v-if="item.todoLists && item.todoLists.length > 0">
                    <p v-for="o in item.todoLists" :key="o.id" class="icontent">
                        <template v-for="s in provideObj.todoStatusDicts">
                            <el-tooltip
                                v-if="s.itemValue === o.status"
                                :key="s.itemValue"
                                class="item"
                                effect="dark"
                                :content="s.title"
                                placement="top"
                            >
                                <i :class="s.class"></i>
                            </el-tooltip>
                        </template>
                        {{ o.title }}
                    </p>
                </template>
            </li>
        </ul>
        <p class="group">协同</p>
        <ul class="group-items">
            <li v-for="item in teamwork" :key="item.employeeId">
                <p class="item-title">
                    <span>{{ item.employeeName }}</span>
                    <span
                        @click="delAss(item)"
                    ><i class="iconfont icon-huishouzhan"></i></span>
                </p>
                <template v-if="item.todoLists && item.todoLists.length > 0">
                    <div
                        v-for="o in item.todoLists"
                        :key="o.id"
                        class="icontent"
                    >
                        <!-- <i
                            :class="{
                                iconfont: true,
                                'icon-a-quandaibanweiwancheng': o.status === 0,
                                'icon-yiwancheng': o.status === 1,
                                'icon-yigezhi': o.status === 2,
                                'icon-yiwancheng confirm': o.status === 9,
                                'icon-yiquxiao': o.status === -1
                            }"
                        ></i> -->
                        <template v-for="s in provideObj.todoStatusDicts">
                            <el-tooltip
                                v-if="s.itemValue === o.status"
                                :key="s.itemValue"
                                class="item"
                                effect="dark"
                                :content="s.title"
                                placement="top"
                            >
                                <i :class="s.class"></i>
                            </el-tooltip>
                        </template>
                        {{ o.title }}
                    </div>
                </template>
            </li>
        </ul>
        <div class="add-assitant">
            <el-popover v-model="dialogVisible" @hide="handleHide">
                <el-form style="padding: 0.3rem 0.3rem 0;">
                    <el-form-item>
                        <el-select
                            v-model="employees"
                            style="width: 2rem; margin-top: 0.15rem;"
                            :value-key="'employeeId'"
                            collapse-tags
                            clearable
                            filterable
                            multiple
                        >
                            <el-option
                                v-for="item in employeeOptions"
                                :key="item.employeeId"
                                :label="item.employeeName"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <!-- <el-button size="mini" @click="handleCancel"
                            >取消</el-button
                        > -->
                        <el-button
                            style="margin-left: 0.1rem;"
                            size="mini"
                            type="primary"
                            @click="handleConfirm"
                        >
                            确定
                        </el-button>
                    </el-form-item>
                </el-form>
                <span slot="reference">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang"></i>新增协同人员
                </span>
            </el-popover>
        </div>
        <todos-dialog
            ref="todosDialog"
            :todo-status-dicts="todoStatusDicts"
            v-on="$listeners"
        ></todos-dialog>
    </div>
</template>

<script>
import todosDialog from './todosDialog'
import todoSvc from 'services/todo'
export default {
    name: 'CoreFocus',
    components: { todosDialog },
    props: {
        employeeList: {
            type: Array,
            default: null
        },
        coreFocusList: {
            type: Object,
            default: null
        },
        todoStatusDicts: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            dialogVisible: false,
            employees: []
        }
    },
    inject: ['provideObj'],
    computed: {
        employeeOptions () {
            let ids1 = (this.subordinate || []).map(item =>
                Number(item.employeeId)
            )
            let ids2 = (this.teamwork || []).map(item =>
                Number(item.employeeId)
            )
            let arr = [...ids1, ...ids2]
            return this.employeeList.filter(
                item => arr.indexOf(Number(item.employeeId)) === -1
            )
        },
        subordinate () {
            return this.coreFocusList.subordinate
        },
        teamwork () {
            return this.coreFocusList.teamwork
        }
    },
    methods: {
        async handleConfirm () {
            await todoSvc.addAss({
                employeeInfos: this.employees
            })
            // this.employees.forEach(item => {
            //     this.subordinate.push({
            //         employeeId: item.employeeId
            //     })
            // })
            this.$message.success('操作成功！')
            this.dialogVisible = false
            this.$emit('handle-refresh')
        },
        handleHide () {
            this.employees = []
        },
        getSelctedEmployeeIds () {},
        handleCancel () {
            this.dialogVisible = false
        },
        async getSomeoneTodoList (item) {
            const { data = {} } = await todoSvc.getSomeoneTodoList({
                employeeId: item.employeeId
            })
            this.showTodosDialog(data, item)
        },
        showTodosDialog (data, item) {
            let _ref = this.$refs.todosDialog
            this.markDelay(data.topTodoList)
            this.markDelay(data.otherTodoList)
            _ref.tempTops = _ref.stickPlans = data.topTodoList
                ? data.topTodoList
                : []
            _ref.notStickPlans = data.otherTodoList ? data.otherTodoList : []
            _ref.employeeId = item.employeeId
            _ref.employeeName = item.employeeName
            _ref.visible = true
        },

        markDelay (arr) {
            if (arr && arr.length > 0) {
                arr.forEach(item => {
                    const scheduleEnd = this.$moment(item.scheduleEnd)
                    const current = this.$moment()
                    const diff = scheduleEnd.diff(current, 'days')
                    if (item.status === 0 && diff < 0) {
                        item.delay = true
                    }
                })
            }
        },
        // 删除协同
        async delAss (item) {
            await todoSvc.delAss({
                employeeInfos: [item]
            })
            this.$message.success('删除成功！')
            this.$emit('handle-refresh')
        }
    }
}
</script>
<style lang="scss" scoped>
.core-focus {
    height: 100%;
    padding: 0 0.2rem;
    //   overflow: auto;
    margin-top: -0.15rem;

    i.icon-a-quandaibanweiwancheng {
        color: #dcdfe6;
    }

    i.icon-yiwancheng {
        color: #52c123;
    }

    i.assign.icon-yiwancheng,
    i.icon-yigezhi {
        color: #e6a23c;
    }

    i.icon-yiquxiao,
    i.icon-weiwancheng {
        color: #df5a68;
    }

    .group {
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.44rem;
        color: #c0c4cc;
    }

    .group-items {
        position: relative;

        li {
            padding: 0 0.1rem 0.1rem;
            background-color: #fff;
            border-radius: 0.08rem;
        }

        li + li {
            box-sizing: border-box;
            margin-top: 0.1rem;
        }
    }

    .item-title {
        position: sticky;
        display: flex;
        justify-content: space-between;
        padding: 0.12rem 0 0.05rem;

        > span:nth-child(1) {
            font-size: 0.18rem;
            font-weight: 500;
            line-height: 0.24rem;
            color: #606266;
        }

        > span:nth-child(2) {
            font-size: 0.18rem;
            color: #a7b6c3;
        }

        .iconfont {
            font-size: 0.14rem;
            cursor: pointer;
        }
    }

    .icontent {
        // display: flex;
        max-width: 3rem;
        overflow: hidden;
        line-height: 0.26rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        .circle {
            display: inline-block;
            width: 0.06rem;
            height: 0.06rem;
            margin-right: 0.1rem;
            background-color: #3595e1;
            border-radius: 50%;
        }
    }

    .add-assitant {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.6rem;
        margin: 0.1rem 0;
        color: #909399;
        border: 2px dashed #dcdfe6;
        border-radius: 0.1rem;

        span {
            cursor: pointer;
        }

        i {
            margin-right: 0.1rem;
            font-size: 0.16rem;
        }
    }
}
</style>
