export const option = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        bottom: '0',
        left: 'center',
        icon: 'circle',
        data: ['P≥110', '100≤P≤110', '90≤P≤100', '70≤P≤90', 'P<70'],
        textStyle: {
            fontSize: '14'
        }
    },
    series: [
        {
            name: 'KPI考核',
            color: ['#DF5A68', '#E6A23C', '#39A36B', '#3595E1', 'purple'],
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            itemStyle: {
                borderWidth: 4, // 边框的宽度
                borderColor: '#fff' // 边框的颜色
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 0,
                    name: 'P≥110'
                },
                { value: 0, name: '100≤P≤110' },
                { value: 0, name: '90≤P≤100' },
                { value: 0, name: '70≤P≤90' },
                { value: 0, name: 'P<70' }
            ]
        }
    ]
}
