<template>
    <el-popover
        placement="bottom"
        v-model="dialogVisible"
        @hide="handleHide"
        @show="handleShow"
    >
        <el-form
            :model="form"
            ref="form"
            class="custom-form"
            :show-message="false"
        >
            <el-form-item label="名称" prop="title" required>
                <el-input
                    placeholder="输入待办事项"
                    v-model.trim="form.title"
                    maxlength="100"
                    show-word-limit
                ></el-input>
            </el-form-item>
            <el-form-item label="优先级" prop="priority" required>
                <el-select v-model="form.priority">
                    <el-option :value="30" label="高">高</el-option>
                    <el-option :value="20" label="中">中</el-option>
                    <el-option :value="10" label="低">低</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="时间段" prop="dateRange" required>
                <el-date-picker
                    v-model="form.dateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                >
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="优先级">
                <el-select disabled v-model="form.repeatId">
                    <el-option value="0" label="不重复">不重复</el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="描述">
                <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="描述一下"
                    v-model="form.description"
                    maxlength="100"
                    show-word-limit
                ></el-input>
            </el-form-item>
        </el-form>
        <div
            style="text-align: right; margin: 0;padding-right: 20px;padding-bottom: 10px"
        >
            <el-button class="custom-button" @click="handleCancel"
                >取消</el-button
            >
            <el-button
                class="custom-button"
                type="primary"
                @click="handleConfirm"
                >确定</el-button
            >
        </div>
        <span class="add-tip" slot="reference">
            <el-button
                class="edit"
                v-if="isEdit"
                size="mini"
                icon="iconfont icon-jinrijihua-bianji"
                circle
            ></el-button>
            <!-- <i
                v-if="isEdit"
                style="margin-left:10px;cursor:pointer"
                class="iconfont icon-bianji"
            ></i> -->
            <el-tooltip
                class="item"
                effect="dark"
                content="新建待办"
                placement="top"
                v-if="!isEdit"
            >
                <el-button
                    size="mini"
                    icon="iconfont icon-jinrijihua-jiahao"
                    circle
                ></el-button>
            </el-tooltip>
        </span>
    </el-popover>
</template>

<script>
import todoSvc from 'services/todo'
import _ from 'lodash'
import bus from 'utils/eventBus'

export default {
    name: 'AddTodoPopover',
    components: {},
    props: {
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: false,
            form: {
                repeatId: '0',
                priority: 20,
                dateRange: [new Date(), new Date()],
                description: ''
            }
        }
    },
    methods: {
        handleHide() {
            this.resetForm()
        },
        handleShow() {
            this.$emit('transform-data')
            this.dialogVisible = true
            console.log(this.form, '====form')
        },
        resetForm() {
            this.$refs.form.resetFields()
            this.form = {
                repeatId: '0',
                priority: 20,
                dateRange: [new Date(), new Date()],
                description: ''
            }
        },
        handleConfirm() {
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    this.form.scheduleStart = this.form.dateRange[0]
                    this.form.scheduleEnd = this.form.dateRange[1]
                    let apiName = this.isEdit ? 'todoEdit' : 'addTodo'
                    if (this.isEdit) {
                        this.form = {
                            todoId: this.form.id,
                            title: this.form.title,
                            priority: this.form.priority,
                            scheduleStart: this.form.scheduleStart,
                            scheduleEnd: this.form.scheduleEnd,
                            description: this.form.description
                        }
                    }
                    await todoSvc[apiName](this.form)
                    this.$message.success(
                        this.isEdit ? '修改成功' : '新建成功！'
                    )
                    this.$emit('refresh-popover')
                    this.handleVisible(false)
                } else {
                    console.log('error submit!!')
                    this.$message.warning('请完善表单内容！')
                    return false
                }
            })
        },
        handleCancel() {
            this.resetForm()
            this.handleVisible(false)
        },
        handleVisible(boo) {
            this.dialogVisible = boo
        },
        transformData() {
            this.$emit('transform-data')
        }
    }
}
</script>
<style lang="less" scoped>
.custom-form {
    margin-top: 20px;
    .w240 {
        width: 240px;
    }
    .el-form-item {
        display: flex;
    }
    /deep/ .el-form-item__label {
        width: 70px;
    }
    /deep/ .el-form-item__content {
        flex: 1;
        padding-right: 20px;
        .el-textarea__inner,
        .el-input__inner {
            border-radius: 0;
        }
        .el-select {
            width: 100%;
        }
    }
}
/deep/ .add-tip {
    .el-button {
        background: #f0f0f0;
        border: none;
        padding: 8px;
        .iconfont {
            font-size: 12px;
            color: #909399;
        }
    }
    .edit.el-button {
      padding: 4px;
    }
    .el-button:hover {
        background: #909399;
        .iconfont {
            color: #fff;
        }
    }
}
.custom-button {
    border-radius: 8px;
}
</style>
