<template>
    <div v-loading="loading" class="history__todos">
        <el-page-header :title="pageTitle" @back="goBack">
            <template slot="content">
                <!-- <el-button size="small">指派待办</el-button> -->
            </template>
        </el-page-header>
        <el-scrollbar style="height: 100%;">
            <div class="history__todos--main">
                <todo-item
                    v-for="item in historyTodos"
                    :key="item.id"
                    :item-data="item"
                    :is-detail="true"
                    :todo-status-dicts="todoStatusDicts"
                >
                    <!-- <template v-slot:rightHandle>
                        <el-dropdown>
                            <i class="iconfont icon-gengduo1"></i>
                            <el-dropdown-menu>
                                <el-dropdown-item @click.native="reOpen(item)">
                                    重新打开
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template> -->
                </todo-item>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import TodoItem from './components/todoItem'
import todoSvc from 'services/todo'
import oaSvc from 'services/oa'
import comSvc from 'services/common'
import Vue from 'vue'

export default {
    name: 'HistoryTodos',
    components: { TodoItem },
    props: {
        pageTitle: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            historyTodos: [],
            todoStatusDicts: [],
            loading: true
        }
    },
    created () {
        this.getHistoryTodos()
    },
    methods: {
        // 获取历史待办
        async getHistoryTodos () {
            try {
                this.loading = true
                const { data = [] } = await todoSvc.getHandledTodos({
                    processedOnHistory: true
                })
                this.historyTodos = data
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        goBack () {
            this.$emit('back-to-todo')
        },
        async reOpen (item) {
            try {
                this.loading = true
                await todoSvc.reOpenTodo({
                    id: item.id
                })
                this.$message.success('操作成功！')
                this.getHistoryTodos()
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.history__todos {
    display: flex;
    flex-direction: column;
    height: 100%;

    ::v-deep .el-page-header {
        align-items: center;
        justify-content: space-between;
        padding: 0 0.2rem;

        &__left::after {
            width: 0;
        }
    }

    &--main {
        padding: 0 0.2rem;
        margin-top: 0.15rem;
    }

    ::v-deep .assign__item {
        height: 0.62rem;
        border-bottom: 1px solid #f0f0f0;

        .icon-gengduo1 {
            cursor: pointer;
        }
    }
}
</style>
