<template>
    <div class="learning-desktop desktop">
        <p class="welcome">{{ currentUser.displayName }}，欢迎使用骨贝平台</p>

        <el-scrollbar style="flex:1">
            <div class="desktop-content">
                <div class="content-item">
                    <ScoreSort />
                    <TodayCourse />
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScoreSort from './cards/scoreSort'
import TodayCourse from './cards/todayCourse'

export default {
    name: 'LearningDesktop',
    components: { ScoreSort, TodayCourse },
    computed: {
        ...mapGetters(['currentUser'])
    },
    data() {
        return {}
    },
    created() {},
    methods: {}
}
</script>
<style lang="less" scoped>
.learning-desktop {
    .welcome {
        font-size: 0.3rem;
        color: #fff;
        font-weight: 400;
        padding-left: 0.2rem;
        padding-top: 0.2rem;
    }
    .desktop-content {
        .content-item {
            padding: 0.2rem;
            display: grid;
            grid-template-rows: repeat(5, 2rem);
            grid-template-columns: 1fr 1fr;
            grid-gap: 0.2rem;
        }
    }
}
</style>
