<template>
    <el-dialog
        v-if="visible"
        class="add-dialog"
        width="10rem"
        :visible.sync="visible"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <template slot="title">
            <span class="custom-title">{{ title }}</span>
        </template>
        <el-tabs v-model="query.ownerType" class="custom-tabs" @tab-click="getWidgets">
            <el-tab-pane
                v-for="{label, name} in tabs"
                :key="name"
                :label="label"
                :name="name"
            >
            </el-tab-pane>
        </el-tabs>
        <el-row class="custom-row" type="flex">
            <el-col class="custom-col">
                <span>模块</span>
                <gb-dictionary
                    v-model="query.widgetGroup"
                    class="w2rem"
                    group="工作台小部件关联模块"
                    :clearable="true"
                    :filterable="true"
                    placeholder="请选择"
                    @change="getWidgets"
                ></gb-dictionary>
            </el-col>
            <el-col class="custom-col">
                <span>组件类型</span>
                <gb-dictionary
                    v-model="query.widgetType"
                    class="w2rem"
                    group="工作台小部件类型"
                    :clearable="true"
                    :filterable="true"
                    placeholder="请选择"
                    @change="getWidgets"
                ></gb-dictionary>
            </el-col>
            <el-col class="custom-col">
                <el-input
                    v-model="query.title"
                    class="w2rem"
                    placeholder="回车搜索组件"
                    @input="getWidgets"
                ></el-input>
            </el-col>
        </el-row>
        <div v-loading="loading" class="content">
            <template v-if="selectedTarget">
                <component-list
                    :widgets="restWidgets"
                    :selected="selectedTarget"
                    class="components"
                    @handle-selected="handleSelected"
                ></component-list>
                <edit-zone
                    class="edit-zone"
                    v-bind="$attrs"
                    :config="config"
                    :edit-type="type"
                    :selected="selectedItem"
                    @handel-box="handelComponentBox"
                ></edit-zone>
            </template>
            <el-empty v-if="!selectedTarget" style="width: 100%;" description="没有查询到相关组件"></el-empty>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from 'lodash'
import gbDictionary from '@/components/dictionary'
import oaSvc from 'services/oa'
import ComponentList from './component-list'
import EditZone from './edit-zone'

export default {
    name: 'AddDialog',
    components: {
        ComponentList,
        EditZone,
        gbDictionary
    },
    data () {
        return {
            type: 'add',
            query: {
                title: null, // 名称模糊
                widgetType: null, // 组件类型：1文本，2图表，3列表，9其他
                widgetGroup: null, // 关联模块（所属分组）
                ownerType: '0',
                current: 1,
                size: 1000
            },
            widgets: [],
            visible: false,
            loading: false,
            config: {
                componentName: null,
                gridBox: {
                }
            }, // 传过来点击位置的组件信息
            box: {}, // 设置的盒子大小信息
            selected: null, // 选中的组件列表中的目标
            tabs: [
                // {
                //     label: '推荐组件',
                //     name: 'user'
                // },
                {
                    label: '官方组件',
                    name: '0'
                },
                {
                    label: '第三方组件',
                    name: '1'
                }
            ]
        }
    },
    computed: {
        title () {
            const titles = {
                add: '新增',
                edit: '修改'
            }
            return '工作台组件设置'
        },
        restWidgets () {
            const widgets = this.widgets || []
            const widgetNo = this.config.gridBox.name || ''
            const addItems = this.$attrs.addItems || []
            const addItemNos = addItems.filter(item => item.name !== widgetNo).map(({ name }) => name)
            return widgets.filter(item => !addItemNos.includes(item.widgetNo))
        },
        selectedItem () {
            const widgets = this.restWidgets
            if (_.isEmpty(widgets)) {
                return null
            }
            // todo if 返回的列表包含this.selected就返回this.selected,否则就返回列表第一项。如果查询列表为空，点确认报错
            const hasWidget = widgets.find(({ widgetNo }) => widgetNo === this.selected)
            if (hasWidget) {
                return hasWidget
            } else {
                return widgets[0]
            }
        },
        selectedTarget () {
            if (!this.selectedItem) {
                return null
            }
            return this.selectedItem.widgetNo
        }
    },
    mounted () {
    },
    methods: {
        handleVisible (visible) {
            this.visible = visible
            if (!visible) {
                this.resetQuery()
            }
        },
        handleOpenDialog (type = 'add', config = {}) {
            const _config = _.cloneDeep(config)
            this.selected = _config.gridBox && _config.gridBox.name ? _config.gridBox.name : null
            this.type = type
            Object.keys(_config).forEach(item => {
                // 打开弹窗，设置组件信息，新增的为空，修改的为点击组件的信息
                this.config[item] = _config[item]
            })
            this.getWidgets()
            this.handleVisible(true)
        },
        resetQuery () {
            ['title', 'widgetType', 'widgetGroup'].forEach(item => {
                this.query[item] = null
            })
            this.query.ownerType = '0'
            this.query.current = 1
        },
        handleSelected ({ widgetNo }) {
            this.selected = widgetNo
        },
        handelComponentBox (box = {}) {
            // 设置组件选中占用空间信息
            Object.keys(box).forEach(item => {
                this.box[item] = box[item]
            })
        },
        handleConfirm () {
            // todo:判断selectedItem是否为空。
            if (!this.selectedTarget) {
                this.$message.warning('未选中任何组件')
                return
            }
            const checked = this.type === 'add' ? true : this.selectedTarget !== this.config.componentName
            if (checked && this.$attrs.addItems.find(item => item.name === this.selectedTarget)) {
                this.$message.warning('选择的组件已经被添加过')
                return
            }
            const config = {}
            config.componentName = this.selectedItem.widgetNo
            config.i = this.selectedItem.widgetNo
            config.gridBox = _.cloneDeep(this.config.gridBox)
            config.gridBox.i = this.selectedItem.widgetNo
            this.box.w && ['w', 'h'].forEach(item => {
                config.gridBox[item] = this.box[item]
            })
            this.$emit('handle-add-widget', config)
            this.handleVisible(false)
        },
        handleCancel () {
            this.handleVisible(false)
        },
        async getWidgets () {
            // 获取用户所有可配置的组件
            this.loading = true
            const { data = [] } = await oaSvc.getAuthorizedWidget(this.query)
            this.widgets = data
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.w2rem {
    width: 2rem;
}

.custom-tabs {
    ::v-deep.el-tabs__nav-wrap::after {
        display: none !important;
    }
}

.custom-title {
    font-size: 0.18rem;
    font-weight: 500;
    color: #606266;
}

.add-dialog {
    .custom-row {
        margin-bottom: 0.2rem;
    }

    .custom-col {
        width: auto;
        margin-right: 0.4rem;

        & > span {
            margin-right: 0.1rem;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        max-height: 6.3rem;

        .components {
            margin-right: 0.3rem;
        }

        .components,
        .edit-zone {
            width: 50%;
            max-height: 6.3rem;
        }
    }

    ::v-deep {
        .el-dialog__body {
            padding: 0.25rem;
        }

        .el-dialog__header {
            padding: 0.25rem 0.25rem 0;
        }

        .el-tabs__item {
            height: auto;
            padding: 0 0.2rem 0.05rem;
            font-size: 0.16rem;
            font-weight: 500;
            line-height: unset;
        }

        .el-tabs__header {
            margin: 0 0 0.2rem;
        }

        .el-dialog__footer {
            padding: 0.1rem 0.16rem;
            border-top: 2px solid #f0f0f0;
        }
    }
}
</style>
