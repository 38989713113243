<template>
    <div id="content" class="layout-grid">
        <grid-layout
            ref="gridlayout"
            :layout.sync="viewItems"
            :col-num="colTotal"
            :max-rows="maxRowTotal"
            :row-height="100"
            :is-draggable="false"
            :static="false"
            :is-resizable="false"
            :is-mirrored="false"
            :vertical-compact="false"
            :margin="[10, 10]"
            :use-css-transforms="true"
        >
            <grid-item
                v-for="item in viewItems"
                :key="item.i"
                :x="item.x"
                :y="item.y"
                :i="item.i"
                :w="item.w"
                :h="item.h"
                :static="item.static"
                :is-draggable="item.draggable"
            >
                <component :is="item.component" v-bind="item.props" v-on="item.events"></component>
            </grid-item>
        </grid-layout>
        <!-- <grid-layout
            :layout.sync="defaultItems"
            :col-num="6"
            :max-rows="80"
            :row-height="100"
            :is-draggable="false"
            :static="false"
            :is-resizable="false"
            :is-mirrored="false"
            :vertical-compact="false"
            :margin="[10, 10]"
            :use-css-transforms="true"
        >
            <grid-item
                v-for="item in defaultItems"
                :key="item.i"
                :x="item.x"
                :y="item.y"
                :i="item.i"
                :w="item.w"
                :h="item.h"
                :static="item.static"
                :is-draggable="item.static"
            >
                <component :is="item.component"></component>
            </grid-item>
        </grid-layout> -->
        <add-drawer-bottom @drag="handleDrag" @drag-end="handleDragEnd"></add-drawer-bottom>
    </div>
</template>
<script>
import { GridLayout, GridItem } from 'vue-grid-layout' // 引入layout布局
import AddDrawerBottom from './add-drawer-bottom.vue'

let mouseXY = { 'x': null, 'y': null }
let DragPos = { 'x': null, 'y': null, 'w': 1, 'h': 1, 'i': null }

export default {
    name: 'LayoutGridDrop',
    components: {
        GridLayout,
        GridItem,
        AddDrawerBottom
    },
    data () {
        return {
            colTotal: 6,
            maxRowTotal: 10,
            grid: null,
            defaultItems: [],
            addItems: []
        }
    },
    computed: {

        viewItems () {
            return [...this.defaultItems, ...this.addItems]
        }
    },
    mounted () {
        this.gridInit()
        document.addEventListener('dragover', function (e) {
            mouseXY.x = e.clientX
            mouseXY.y = e.clientY
        }, false)
    },
    methods: {
        handleDrag () {
            let parentRect = document.getElementById('content').getBoundingClientRect()
            let mouseInGrid = false
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true
            }
            if (mouseInGrid === true) {
                console.log('mouse end')
                // this.addItems.push({
                //     x: (this.addItems.length * 2) % (this.colTotal || 12),
                //     y: this.addItems.length + (this.colTotal || 12), // puts it at the bottom
                //     w: 1,
                //     h: 1,
                //     i: this.addItems.length + 1
                // })
            }
            let index = this.addItems.findIndex(item => item.i === this.addItems.length + 1)
            if (index !== -1) {
                try {
                    this.$refs.gridlayout.$children[this.addItems.length].$refs.item.style.display = 'none'
                } catch {
                }
                let el = this.$refs.gridlayout.$children[index]
                el.dragging = { 'top': mouseXY.y - parentRect.top, 'left': mouseXY.x - parentRect.left }
                let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
                if (mouseInGrid === true) {
                    this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1)
                    DragPos.i = String(index)
                    DragPos.x = this.addItems[index].x
                    DragPos.y = this.addItems[index].y
                }
                if (mouseInGrid === false) {
                    this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1)
                    this.addItems = this.addItems.filter(obj => obj.i !== this.addItems.length + 1)
                }
            }
        },
        handleDragEnd () {
            let parentRect = document.getElementById('content').getBoundingClientRect()
            let mouseInGrid = false
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true
            }
            if (mouseInGrid === true) {
                console.log('222')
                this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1)
                this.addItems.push({
                    x: (this.addItems.length * 2) % (this.colTotal || 12),
                    y: this.addItems.length + (this.colTotal || 12), // puts it at the bottom
                    w: 1,
                    h: 1,
                    i: this.addItems.length + 1
                })
            }
        },
        handleDialog (visible, config) {
            if (visible) {
                this.$refs.addDialog.handleOpenDialog('add', config)
            }
        },
        createDefaultLayout () {
            // 默认站位item
            const addCard = () => import('./add-card.vue')
            // const total = this.colTotal * this.maxRowTotal
            // let row = 0
            // const items = []
            // for (let i = 0; i < total; i++) {
            //     const x = i % 6
            //     items.push({
            //         i,
            //         x,
            //         y: row,
            //         w: 1,
            //         h: 1,
            //         static: false,
            //         component: addCard,
            //         events: {
            //             'handle-dialog': this.handleDialog
            //         },
            //         props: {
            //             i,
            //             x,
            //             y: row,
            //             w: 1,
            //             h: 1
            //         }
            //     })
            //     if (Math.floor((i + 1) / 6) !== row) {
            //         row = Math.floor((i + 1) / 6)
            //     }
            // }
            return [{
                i: 0,
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                component: addCard
            }]
        },
        gridInit () {
            this.defaultItems = this.createDefaultLayout()
        },
        handleAddWidget (config) {
            // 添加组件到布局
            const { gridBox, componentName } = config
            this.addItems.push({
                ...gridBox,
                static: false,
                component: () => import('components/search-query/' + componentName + '.vue')
            })
            // this.defaultItems = this.defaultItems.reduce((prev, current) => {
            //     if (current.i === gridBox.i) {
            //         // 替换掉当前i相同的空白组件
            //         ['x', 'y', 'w', 'h', 'i'].forEach(item => {
            //             current[item] = gridBox[item]
            //             current.props[item] = gridBox[item]
            //         })
            //         current.component = () => import('components/search-query/' + componentName + '.vue')
            //     } else {
            //         const { x, y, w, h } = current
            //         const gridX = gridBox.x
            //         const gridY = gridBox.y
            //         const gridW = gridBox.w
            //         const gridH = gridBox.h
            //         if (x >= gridX && x < gridX + gridW && y >= gridY && y < gridY + gridH) {
            //             // 清除添加组件所站位范围内的空白添加组件
            //             current = null
            //         }
            //     }
            //     current && prev.push(current)
            //     return prev
            // }, [])
        },
        handleEditWidget () {
            // 编辑组件
        }
        // addNewWidget: function () {
        //     const node = this.$options.items[this.count] || {
        //         x: Math.round(12 * Math.random()),
        //         y: Math.round(5 * Math.random()),
        //         w: Math.round(1 + 3 * Math.random()),
        //         h: Math.round(1 + 3 * Math.random())
        //     }
        //     node.id = node.content = String(this.count++)
        //     this.grid.addWidget(node)
        // }
    }
}
</script>
