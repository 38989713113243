<template>
    <el-select
        class="select-all"
        :value="value"
        v-bind="$attrs"
        :collapse-tags="collapse"
        @change="handleChange"
    >
        <el-option
            v-for="item in options"
            :key="item[itemKey]"
            :label="item[itemLabel]"
            :value="item[itemKey]"
        >
        </el-option>
    </el-select>
</template>
<script>
export default {
    name: 'SelectAll',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: [String, Array],
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        itemLabel: {
            type: String,
            default: 'label'
        },
        itemKey: {
            type: String,
            default: 'id'
        },
        collapseTags: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            targetVal: null
        }
    },
    computed: {
        collapse () {
            if (this.collapseTags === false) {
                return false
            } else {
                if (this.$attrs.multiple || this.$attrs.multiple === '') {
                    return true
                }
                return false
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (val) {
                this.targetVal = val
            }
        }
    },
    methods: {
        handleChange (val) {
            if (this.$attrs.multiple || this.$attrs.multiple === '') {
                this.$emit('change', val)
            } else {
                this.$emit('change', val)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.select-all {
    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}
</style>
