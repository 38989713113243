<template>
  <div class="desktop">
    <ul class="desktop-orgs">
      <li
        v-for="(item, index) in groups"
        :key="index"
        :class="{ active: iscur === index }"
        @click="changeOrg(index)"
      >
        {{ item.groupName }}
      </li>
    </ul>
    <el-scrollbar :native="false" :noresize="false" tag="section">
      <div class="desktop-content">
        <div class="content-item" v-if="groups.length > 0">
          <task-finish-condition>
          </task-finish-condition>
          <value-percent class="value-percent"></value-percent>
          <!-- <store-monitor size="samll" class="store-monitor" ref="storeMonitor">
            <template v-slot:cardOption>
              <el-select
                style="width: 1.2rem"
                v-model="brandId"
                placeholder="请选择"
                @change="vendorChange()"
                size="small"
              >
                <el-option
                  v-for="item in brands"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </store-monitor> -->
          <Pending />

          <FinanceCard :groupId="Number(currentGroupInfo.groupId)" />
          <GoodsOperateChart
            class="item2"
            ref="goodsOperatorCard"
            :group="currentGroupInfo"
            :title="currentGroupInfo.leaf ? 'TOP5商品经营图' : 'TOP5店铺经营图'"
          />
          <Matrix class="item4"></Matrix>
        </div>
        <!-- <GoodsOperateChart :title="'TOP5店铺经营图'" :options="stores" /> -->
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import FinanceCard from "./cards/finance"
import GoodsOperateChart from "./cards/goodsOperate"
import Pending from "./cards/pending"
import valuePercent from "./cards/valuePercent"
import productSvc from "services/product"
// import storeMonitor from "./cards/storeMonitor"
import tradeSvc from "services/trade"
import taskFinishCondition from "./cards/taskFinishCondition"
import Matrix from './cards/productMatrix'

export default {
  name: "OperatorManagerDesktop",
  components: {
    FinanceCard,
    GoodsOperateChart,
    Pending,
    valuePercent,
    // storeMonitor,
    taskFinishCondition,
    Matrix
  },
  data() {
    // 这里存放数据
    return {
      iscur: 0,
      // 店铺数据
      stores: [],
      groups: [],
      currentGroupInfo: {},
      brandId: null
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getUserGroups()
    this.getBrands()
  },
  mounted() {},
  // 方法集合
  methods: {
    async getBrands() {
      try {
        const { data = [] } = await productSvc.getBrands()
        data.unshift({
          id: 0,
          brandName: "全部",
        })
        this.brands = data
      } catch (error) {
        console.log(error)
      }
    },
    getUserGroups() {
      this.$axios.fetch("oaServer", "/oa/employee-info-ehr").then((res) => {
        if (res.data) {
          this.groups = res.data.employeeGroupList
          // this.groups = [res.data.employeeGroupList[0]]
          this.currentGroupInfo = this.groups[0]
          this.$nextTick(() => {
            this.$refs.goodsOperatorCard.initData()
          })
        }
      })
    },
    changeOrg(index) {
      this.iscur = index
      this.currentGroupInfo = this.groups[index]
    },
    // 库存监控及供应链日报多条件查询接口
    async getStockMonitor() {
      try {
        let param = {
          brandId: this.brandId,
        }
        const { data = [] } = await tradeSvc.getStockMonitor(param)
        this.stockMonitorData = data
        this.$nextTick(() => {
          this.$refs.storeMonitor.tableData = data
        })
      } catch (error) {
        console.log(error)
      }
    },
    vendorChange() {
      this.getStockMonitor()
    },
  },
}
</script>
<style lang="less" scoped>
.desktop {
  /deep/ .el-scrollbar {
    width: 100%;
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden !important;
    }
  }
  &-orgs {
    display: flex;
    padding-left: 30px;
    margin-top: 15px;
    li {
      line-height: 26px;
      font-size: 18px;
      color: #a7b6c3;
      font-weight: 500;
      margin: 0 20px;
      cursor: pointer;
      padding: 10px 0;
    }
    li.active {
      color: #3595e1;
      position: relative;
    }
    li.active::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 90%;
      border-radius: 2px;
      background: #3595e1;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-content {
    // padding: 0.15rem 0.2rem;
    .content-item {
      padding: 0.2rem;
      display: grid;
      grid-template-rows: repeat(9, 2rem);
      grid-template-columns: repeat(5, 1fr);
      grid-auto-flow: row dense;
      grid-gap: 0.2rem;
      .value-percent {
        grid-column-start: span 1;
      }
      .store-monitor {
        grid-column-start: span 1;
      }
      .item4 {
            grid-column-start: 1;
            grid-column-end: 6;
            grid-row-start: 5;
            grid-row-end: 11;
        }
    }
  }
}
</style>
