export const option = {
    title: {
        text: '人数',
        top: '4%',
        textStyle: {
            color: '#A7B6C3',
            fontSize: 14,
            fontWeight: 400
        }
    },
    grid: {
        left: '12px',
        right: '12px',
        // bottom: '60px',
        containLabel: true
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: [],
        top: '15px',
        icon: 'circle',
        itemGap: 10,
        type: 'scroll'
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {},
    //   },
    // },
    xAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        type: 'category',
        data: [],
        axisLabel: {
            show: true,
            textStyle: {
                color: '#A7B6C3'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: { show: false },
        axisLine: { show: false },
        splitLine: { show: false },
        splitArea: {
            show: true,
            areaStyle: {
                color: ['#EDF1F4', 'transparent']
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#A7B6C3'
            }
        }
    },
    series: []
}
