<template>
    <div class="desktop">
        <ul class="orgs">
            <li
                v-for="(item, index) in groups"
                :key="index"
                :class="{ active: iscur === index }"
                @click="changeOrg(index)"
            >
                {{ item.groupName }}
            </li>
        </ul>
        <el-scrollbar :native="false" :noresize="false" tag="section">
            <div v-if="groups.length > 0" class="content">
                <FinanceCard
                    class="item1"
                    sign="运营"
                    :group-id="Number(currentGroupInfo.groupId)"
                />
                <GoodsOperateChart
                    ref="goodsOperatorCard"
                    class="item2"
                    :group="currentGroupInfo"
                    :title="
                        currentGroupInfo.leaf
                            ? 'TOP5商品经营图'
                            : 'TOP5店铺经营图'
                    "
                />
                <Pending class="item3" />
                <Analysis class="item5" />
                <Matrix class="item6"></Matrix>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import FinanceCard from './cards/finance'
import GoodsOperateChart from './cards/goodsOperate'
import Pending from './cards/pending'
import Analysis from './cards/analysis'
import Matrix from './cards/productMatrix'

export default {
    name: 'OperatorDesktop',
    components: { FinanceCard, GoodsOperateChart, Pending, Analysis, Matrix },
    data () {
        // 这里存放数据de
        return {
            iscur: 0,
            goods: [
                {
                    value: 0,
                    label: '平板电脑贴膜'
                },
                {
                    value: 1,
                    label: '选项2'
                },
                {
                    value: '选项3',
                    label: '选项3'
                },
                {
                    value: '选项4',
                    label: '选项4'
                },
                {
                    value: '选项5',
                    label: '选项5'
                }
            ],
            groups: [],
            currentGroupInfo: {}
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {
        this.getUserGroups()
    },
    mounted () {},
    // 方法集合
    methods: {
        getUserGroups () {
            this.$axios.fetch('oaServer', '/oa/employee-info-ehr').then(res => {
                if (res.data) {
                    this.groups = res.data.employeeGroupList
                    this.currentGroupInfo = this.groups[0]
                    this.$nextTick(() => {
                        this.$refs.goodsOperatorCard.initData()
                    })
                }
            })
        },
        changeOrg (index) {
            this.iscur = index
            this.currentGroupInfo = this.groups[index]
        }
    }
}
</script>
<style lang="less" scoped>
.desktop {
    .orgs {
        display: flex;
        padding-left: 30px;
        margin-top: 15px;

        li {
            padding: 10px 0;
            margin: 0 20px;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            color: #a7b6c3;
            cursor: pointer;
        }

        li.active {
            position: relative;
            color: #3595e1;
        }

        li.active::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 90%;
            height: 3px;
            content: '';
            background: #3595e1;
            border-radius: 2px;
            transform: translateX(-50%);
        }
    }

    .content {
        display: grid;
        grid-template-rows: repeat(10, 2rem);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        // display: flex;
        // justify-content: space-between;
        // padding: .15rem .2rem;
        padding: 0.2rem;

        .item1 {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .item2 {
            grid-column-start: 2;
            grid-column-end: 5;
            // grid-row-start: 1;
            // grid-row-end: 3;
        }

        .item3 {
            grid-column-start: 5;
            grid-column-end: 6;
            // grid-row-start: 1;
            // grid-row-end: 5;
        }

        .item5 {
            grid-row-start: 3;
            grid-row-end: 7;
            grid-column-start: 1;
            grid-column-end: 5;
        }

        .item6 {
            grid-row-start: 7;
            grid-row-end: 11;
            grid-column-start: 1;
            grid-column-end: 6;
        }
    }

    /deep/ .el-scrollbar {
        width: 100%;
        height: 100%;

        .el-scrollbar__wrap {
            overflow-x: hidden !important;
        }
    }
}
</style>
