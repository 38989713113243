<template>
    <!-- 我的指派 -->
    <div class="todo-assign">
        <el-page-header :title="pageTitle" @back="goBack">
            <template slot="content">
                <!-- <el-button size="small">指派待办</el-button> -->
            </template>
        </el-page-header>
        <el-collapse v-model="activeNames" accordion @change="handleChange">
            <el-collapse-item
                v-for="(type, index) in types"
                :key="type"
                :title="type"
                :name="type"
            >
                <draggle-list
                    :list="
                        index === 0
                            ? followUp
                            : index === 2
                                ? assignHistory
                                : assignToday
                    "
                    group-name="groupName"
                    :disabled="true"
                >
                    <template v-slot:default="item">
                        <todo-item
                            v-if="pageTitle === '我指派的'"
                            sign="指派"
                            :right-dropdown-handles="
                                index === 0
                                    ? rightDropdownHandles
                                    : index === 1
                                        ? handles
                                        : null
                            "
                            :item-data="item.data"
                            :is-edit="index === 0 || index === 1"
                            :is-detail="index === 2"
                        ></todo-item>
                        <todo-item
                            v-if="pageTitle === '我参与的'"
                            sign="指派"
                            :right-dropdown-handles="null"
                            :item-data="item.data"
                            :is-detail="true"
                        ></todo-item>
                    </template>
                </draggle-list>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
// import TodoEditPopover from './components/todoEditPopover'
import { getDateRangeDisplay } from '@/utils/date'
import DraggleList from '@/components/draggable-list'
import TodoItem from './components/todoItem'
import comSvc from 'services/common'
import oaSvc from 'services/oa'

export default {
    name: 'TodoAssign',
    components: {
        // TodoEditPopover,
        DraggleList,
        TodoItem
    },
    props: {
        todoAssignData: {
            type: Object,
            default: null
        },
        pageTitle: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            activeNames: ['跟进中'],
            types: ['跟进中', '历史'],
            rightDropdownHandles: [
                // 如果待办状态为待处理：可进行催办（本期不做）、搁置、取消操作
                // 如果待办状态为完成待确认，可进行确认、未解决、取消操作，确认后待办状态为已完成
                { label: '搁置', status: [0] },
                // { label: '催办', status: [0,2] },// 本期不做
                { label: '取消', status: [0, 1, 9] },
                { label: '确认', status: [9] },
                { label: '未解决', status: [9] },
                { label: '重新打开', status: [2] }
            ],
            handles: [{ label: '重新打开', status: null }]
        }
    },
    computed: {
        followUp () {
            return this.todoAssignData && this.todoAssignData.followUp
                ? this.todoAssignData.followUp
                : []
        },
        assignHistory () {
            return this.todoAssignData && this.todoAssignData.assignHistory
                ? this.todoAssignData.assignHistory
                : []
        },
        assignToday () {
            return this.todoAssignData && this.todoAssignData.assignToday
                ? this.todoAssignData.assignToday
                : []
        }
    },
    created () {
        this.types =
            this.pageTitle === '我指派的'
                ? ['跟进中', '今日已处理', '历史指派']
                : ['跟进中', '历史']
    },
    methods: {
        getDateRangeDisplay,
        handleChange () {},
        goBack () {
            this.$emit('back-to-todo')
        }
    }
}
</script>
<style lang="less" scoped>
.todo-assign {
    // padding: 0 0.2rem;
    height: 100%;
    overflow: auto;

    /deep/ .el-page-header {
        align-items: center;
        justify-content: space-between;
        padding: 0 0.2rem;

        &__left::after {
            width: 0;
        }
    }

    /deep/ .el-collapse {
        border: none;

        .el-collapse-item__header {
            color: #409eff;
        }

        .el-collapse-item__wrap,
        .el-collapse-item__header {
            padding: 0 0.2rem;
            border: none;
        }
    }

    .assign__item {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-left: 0.1rem;
        border-bottom: 1px solid #f0f0f0;

        i.icon-yiwancheng {
            color: #52c123;
        }

        i.icon-yigezhi {
            color: #e6a23c;
        }

        i.icon-yiquxiao,
        i.icon-weiwancheng {
            color: #df5a68;
        }

        i.icon-gengduo1 {
            cursor: pointer;
        }

        &--status {
            i {
                font-size: 0.2rem;
            }
        }

        &--middle {
            flex: 1;
            padding-left: 0.1rem;
            overflow: hidden;

            /deep/ .todo__title {
                max-width: 2.4rem;
                overflow: hidden;
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.32rem;
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            p:nth-child(2) {
                font-size: 0.14rem;
                font-weight: 400;
                color: #c0c4cc;
            }
        }
    }
}
</style>
