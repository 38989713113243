export const option = {
    wareHouse: [],
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        formatter: function (params) {
            // console.log(params, '==arguments')
            return (
                // params[0].marker +
                params[0].axisValueLabel +
                (params[0].data.warehouseName ? '_' + params[0].data.warehouseName : '') +
                ': ' +
                // '<br />' +
                // params[0].marker +
                params[0].data.value +
                '%'
            )
        }
    },
    grid: {
        left: '0%',
        right: '4%',
        bottom: '3%',
        top: '6%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisTick: { show: false },
        axisLine: { show: false },
        // axisLabel: { show: false },
        // splitLine: { show: false }
        axisLabel: {
            formatter: function (value) {
                return value + '%'
            }
        }
    },
    yAxis: {
        type: 'category',
        data: [],
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
            interval: 0,
            color: '#54636F',
            formatter: function (value) {
                return value.length > 11 ? value.substr(0, 11) + '...' : value
            }
        }
    },
    series: {
        barWidth: '12',
        type: 'bar',
        data: [],
        itemStyle: {
            normal: {
                color: function (params) {
                    if (params.value < 10) {
                        return '#69ACF1'
                    } else {
                        return '#DF5A68'
                    }
                }
            }
        }
    }
}
