var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"purchase-desktop desktop"},[_c('group-tab',{attrs:{"groups":_vm.groups},on:{"groupChange":_vm.changeOrg}}),_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"purchase-desktop-main"},[_c('sumDataCard',{attrs:{"isMoney":false,"cardIcon":"iconfont icon-caigougenjin","apiName":"getPurchaseFlow","fields":['pendingReview', 'underReview', 'inExecution'],"items":_vm.cggjItems}}),_c('sumDataCard',{staticClass:"sum2",attrs:{"isMoney":false,"cardTitle":"入库结算","cardIcon":"iconfont icon-kucunjiankong","apiName":"getSettlement","fields":[
                    'toBeStored',
                    'partialStorage',
                    'storedToBeSettled',
                    'partialSettled'
                ],"items":_vm.rkjsItems}}),_c('arrive-monitor',{attrs:{"cardIcon":"iconfont icon-chukuhuozhi","cardLink":"/purchase/follow"}}),_c('cash-medium',{attrs:{"icon":"iconfont icon-yingfu","title":_vm.contant.financeDesktopText.ACTUAL_PAY,"sumData":_vm.yfSumData,"columns":_vm.yfColumns,"datas":_vm.yfData},scopedSlots:_vm._u([{key:"cashOption",fn:function(){return [_c('el-select',{staticStyle:{"width":"1.2rem"},attrs:{"placeholder":"请选择","size":"small"},on:{"change":function($event){return _vm.parenterChange(
                                _vm.contant.financeDesktopText.ACTUAL_PAY
                            )}},model:{value:(_vm.yfParenterId),callback:function ($$v) {_vm.yfParenterId=$$v},expression:"yfParenterId"}},_vm._l((_vm.partners),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]},proxy:true}])}),_c('Pending',{staticClass:"item-pending"}),_c('purchase-range'),_c('value-percent')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }