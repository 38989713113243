/*
生成guid
*/
export const guid = function () {
    let guid = ''
    for (let i = 1; i <= 32; i++) {
        const n = Math.floor(Math.random() * 16.0).toString(16)
        guid += n
    }
    return guid
}

// 适配分辨率的echarts-一般字体
export const getAdapterFont = (e = 7) => {
    e = e || 0
    const wid = document.body.clientWidth
    if (wid < 3000) {
        return (document.body.clientWidth / 1000) * e
    } else {
        return (1920 / 1000) * e * 1.5
    }
}
