<template>
    <div class="desktop">
        <ul class="orgs">
            <li
                v-for="(item, index) in groups"
                :key="index"
                :class="{ active: iscur === index }"
                @click="changeOrg(index)"
            >
                {{ item.groupName }}
            </li>
        </ul>
           <el-scrollbar :native="false" :noresize="false" tag="section">
        <div class="content" v-if="groups.length > 0">
            <EmployeeCondition class="item1" />
               <DistributorCard 
                    class="item2"
                    title="待招岗位"
                    :content-data="distributorDatas"
                    :link="'/oa/requirementList'"
                />
            <Pending class="item3" />
            <TalentsCard class="item4" :groupId="groups[0].groupId"  />
            <TalentsCardApi class="item5"/>
        </div>
           </el-scrollbar>
    </div>
</template>

<script>
import TalentsCard from './cards/talents'
import TalentsCardApi from './cards/talentsApi'
import DistributorCard from './cards/jobDistributor'
import GoodsOperateChart from './cards/goodsOperate'
import Pending from './cards/pending'
import PlanJobs from './cards/planJobs'
import EmployeeCondition from './cards/employeeCondition'
import ehr from "@/services/ehr"
export default {
    name: 'OperatorDesktop',
    components: {
        TalentsCard,
        GoodsOperateChart,
        PlanJobs,
        Pending,
        TalentsCardApi,
        EmployeeCondition,
        DistributorCard,
        
    },
    data() {
        // 这里存放数据
        return {
            iscur: 0,
            goods: [
                {
                    value: 0,
                    label: '平板电脑贴膜'
                },
                {
                    value: 1,
                    label: '选项2'
                },
                {
                    value: '选项3',
                    label: '选项3'
                },
                {
                    value: '选项4',
                    label: '选项4'
                },
                {
                    value: '选项5',
                    label: '选项5'
                }
            ],
            distributorDatas:[],
            groups: [],
            date:'',
            searchData:{}
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        
     let today = this.$moment().format('YYYY-MM-DD');
     let endDay = this.$moment().add(30, 'days').format('YYYY-MM-DD');
     this.searchData.dateStart = today
     this.searchData.dateEnd = endDay
        this.getUserGroups()
        this.getJobRequirement()
        //拼接时间
    },
    mounted() {},
    // 方法集合
    methods: {
        getUserGroups() {
            this.$axios.fetch('oaServer', '/oa/employee-info-ehr').then(res => {
                if (res.data) {
                    this.groups = [res.data.employeeGroupList[0]]
                    this.$nextTick(() => {
                        // this.$refs.goodsOperatorCard.initData()
                    })
                }
            })
        },
        changeOrg(index) {
            this.iscur = index
        },
        /**
         * 获得待招岗位信息
         */
        async getJobRequirement(){

            console.log("this.searchData:"+JSON.stringify(this.searchData));
           const res = await ehr.jobRequirementInde(this.searchData)
           this.assemble(res)
        },

        assemble(res){
              if (res.data && res.data.length > 0) {
                        const levels = [
                            ...new Set(
                                res.data.map(
                                    item => item.groupName
                                )
                            )
                        ]
                        levels.forEach(item => {
                            const obj = {
                                title: item,
                                datas: []
                            }
                            res.data.forEach(data => {
                                if (data.groupName === item) {
                                    // if(obj.datas.length >0){
                                    //    for(var i = 0; i< obj.datas.length; i++){
                                    //      if(obj.datas[i].jobId === data.jobId){
                                    //          data.expectedCount = data.expectedCount+obj.datas[i].expectedCount
                                    //          obj.datas.splice(i,1);
                                    //          i--;
                                    //      }
                                    //    }
                                    // }
                                    
                                    obj.datas.push(data)
                                }
                            })
                             this.distributorDatas.push(obj)
                          
                        })
                    }

        }

    }
}
</script>
<style lang="less" scoped>
.desktop {
    .orgs {
        display: flex;
        padding-left: 30px;
        margin-top: 15px;
        li {
            line-height: 26px;
            font-family: PingFangSC-Medium;
            font-size: 18px;
            color: #a7b6c3;
            font-weight: 500;
            margin: 0 20px;
            cursor: pointer;
            padding: 10px 0;
        }
        li.active {
            color: #3595e1;
            position: relative;
        }
        li.active::after {
            content: '';
            position: absolute;
            height: 3px;
            width: 90%;
            border-radius: 2px;
            background: #3595e1;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .content {
        padding: 0.2rem;
        display: grid;
        grid-template-rows: repeat(4, 2rem);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        .item1 {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 3;
        }
        .item2 {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 5;
        }
        .item3 {
            grid-row-start: 1;
            grid-row-end: 5;
            grid-column-start: 5;
            grid-column-end: 6;
        }
        .item4 {
            grid-row-start: 3;
            grid-row-end: 5;
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }
}
</style>
