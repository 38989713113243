var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"todos-dialog",attrs:{"visible":_vm.visible,"title":_vm.title,"width":"500px","lock-scroll":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('draggable',{staticClass:"plans stick",attrs:{"element":"ul","group":"group1","animation":"150"},model:{value:(_vm.stickPlans),callback:function ($$v) {_vm.stickPlans=$$v},expression:"stickPlans"}},[_c('transition-group',_vm._l((_vm.stickPlans),function(item){return _c('li',{key:item.title},[_c('i',{staticClass:"iconfont icon-zhidingjiaobiao"}),_c('el-tooltip',{staticClass:"tipItem",attrs:{"effect":"dark","content":"取消置顶","placement":"top"}},[_c('span',{staticClass:"icon",on:{"click":function($event){return _vm.cancelTop(item)}}},[_c('i',{staticClass:"el-icon-download"})])]),_c('div',{staticClass:"li-content"},[_c('p',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]))+" ")])]),_c('div',{staticClass:"li-tags"},[_c('span',{class:{
                            high: item.priority === 30,
                            mid: item.priority === 20,
                            low: item.priority === 10
                        }},[_vm._v(_vm._s(_vm._f("priorityFilter")(item.priority)))]),(item.delay)?_c('span',{staticClass:"delay"},[_vm._v(" 延期 ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.dicFilter( null, null, item.status, null, _vm.todoStatusDicts?_vm.todoStatusDicts:[] )))])])],1)}),0)],1),_c('draggable',{staticClass:"plans stick",attrs:{"element":"ul","group":_vm.group2Name,"disabled":""},model:{value:(_vm.tempTops),callback:function ($$v) {_vm.tempTops=$$v},expression:"tempTops"}},_vm._l((_vm.stickCount),function(item){return _c('li',{key:item,staticClass:"null item"},[_c('span',{staticClass:"empty"},[_c('i',{staticClass:"el-icon-upload2"}),_vm._v(" 下方待办可拖入置顶 ")])])}),0),_c('draggable',{staticClass:"plans not-stick",attrs:{"group":"stick","element":"ul","animation":"100","drag-class":"dragClass","ghost-class":"ghostClass","chosen-class":"chosenClass"},on:{"end":_vm.onEnd},model:{value:(_vm.notStickPlans),callback:function ($$v) {_vm.notStickPlans=$$v},expression:"notStickPlans"}},[_c('transition-group',_vm._l((_vm.notStickPlans),function(item){return _c('li',{key:item.title},[_c('div',{staticClass:"li-content"},[_c('p',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.getDateRangeDisplay([item.scheduleStart,item.scheduleEnd]))+" ")])]),_c('div',{staticClass:"li-tags"},[_c('span',{class:{
                            high: item.priority === 30,
                            mid: item.priority === 20,
                            low: item.priority === 10
                        }},[_vm._v(_vm._s(_vm._f("priorityFilter")(item.priority)))]),(item.delay)?_c('span',{staticClass:"delay"},[_vm._v(" 延期 ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.dicFilter( null, null, item.status, null, _vm.todoStatusDicts?_vm.todoStatusDicts:[] )))])])])}),0)],1),_c('ul',{staticClass:"plans not-stick"},_vm._l((_vm.notStickCount),function(item){return _c('li',{key:item,staticClass:"null"})}),0),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }