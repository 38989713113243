<template>
    <div class="layout-grid">
        <grid-layout
            :layout="views"
            :col-num="colTotal"
            :max-rows="maxRowTotal"
            :row-height="210"
            :is-draggable="isEdit"
            :static="!isEdit"
            :is-resizable="false"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[20, 20]"
            :use-css-transforms="true"
            @layout-updated="layoutUpdatedEvent"
        >
            <grid-item
                v-for="item in views"
                :key="item.i"
                class="custom-grid-item"
                :x="item.x"
                :y="item.y"
                :i="item.i"
                :w="item.w"
                :h="item.h"
                :static="item.static"
                :is-draggable="item.draggable"
                @moved="handleMoved"
            >
                <div
                    v-if="!['addCard', 'default'].includes(item.name) && isEdit"
                    class="mask-lock"
                >
                    <i
                        class="iconfont icon-bianji"
                        @click.stop="handleEditDialog(item)"
                    ></i>
                    <i
                        class="iconfont icon-huishouzhan"
                        @click.stop="handleRemove(item)"
                    ></i>
                </div>
                <component
                    :is="item.component"
                    v-bind="item.props"
                    v-on="item.events"
                ></component>
            </grid-item>
        </grid-layout>
        <add-dialog
            ref="addDialog"
            v-bind="{
                addItems: defaultAddItems,
                colTotal: colTotal,
                maxRowTotal: maxRowTotal
            }"
            @handle-add-widget="handleAddWidget"
        ></add-dialog>
    </div>
</template>
<script>
import _ from 'lodash'
import oaSvc from 'services/oa'
import { GridLayout, GridItem } from 'vue-grid-layout' // 引入layout布局
import AddDialog from './add-dialog.vue'

export default {
    name: 'LayoutGrid',
    components: {
        GridLayout,
        GridItem,
        AddDialog
    },
    data () {
        return {
            views: [],
            colTotal: 5,
            maxRowTotal: 40,
            grid: null,
            // defaultItems: [],
            addItems: [],
            isEdit: false,
            drap: false,
            initTotal: 0,
            moved: false,
            defaultCard: {
                i: 'default',
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                name: 'default',
                static: true,
                component: () => import('modules/desktop/widgets/default.vue'),
                events: {
                    'handle-edit': this.handleEdit
                }
            }
        }
    },
    computed: {
        defaultItems () {
            if (this.drap) {
                // todo: 拖动不在计算
                return []
            }
            // 默认站位item
            const addCard = () => import('./add-card.vue')
            const total = this.colTotal * this.maxRowTotal
            const isEdit = this.isEdit
            let row = 0
            const items = []
            for (let i = 0; i < total; i++) {
                const x = i % this.colTotal
                items.push({
                    i,
                    x,
                    y: row,
                    w: 1,
                    h: 1,
                    static: false,
                    draggable: false,
                    name: 'addCard',
                    // isEdit,
                    component: addCard,
                    events: {
                        'handle-dialog': this.handleDialog
                    },
                    props: {
                        i,
                        x,
                        y: row,
                        w: 1,
                        h: 1,
                        isEdit
                    }
                })
                if (Math.floor((i + 1) / this.colTotal) !== row) {
                    row = Math.floor((i + 1) / this.colTotal)
                }
            }
            return items
        },
        defaultAddItems () {
            return [this.defaultCard, ...this.addItems]
        },

        viewItems () {
            // const iList = this.defaultItems.map(item => item.i)
            const defaultAddItems = _.cloneDeep(this.defaultAddItems)
            const isWithinScope = (x, y, w, h) => {
                return !!defaultAddItems.find(item => {
                    return (
                        x >= item.x &&
                        x < item.x + item.w &&
                        y >= item.y &&
                        y < item.y + item.h
                    )
                })
            }
            const defaultItems = _.cloneDeep(this.defaultItems)
            const restDefaultItems = defaultItems.reduce((prev, current) => {
                const { x, y, w, h } = current
                if (isWithinScope(x, y, w, h)) {
                    // 清除添加组件所站位范围内的空白添加组件
                    current = null
                }
                current && prev.push(current)
                return prev
            }, [])
            return [...restDefaultItems, ...defaultAddItems]
            // return defaultItems.reduce((prev, current) => {
            //     if (iList.includes(current.i)) {
            //         // 替换掉当前i相同的空白组件
            //         const target = this.defaultAddItems.find(
            //             item => item.i === current.i
            //         )
            //         ;['x', 'y', 'w', 'h', 'i', 'static'].forEach(item => {
            //             current[item] = target[item]
            //             current.props[item] = target[item]
            //         })
            //         current.name = target.name
            //         current.events = target.events
            //         current.component = target.component
            //         current.draggable = target['static'] || this.isEdit
            //     } else {
            //         const { x, y, w, h } = current
            //         if (isWithinScope(x, y, w, h)) {
            //             // 清除添加组件所站位范围内的空白添加组件
            //             current = null
            //         }
            //     }
            //     current && prev.push(current)
            //     return prev
            // }, [])
        },
        editAddItems () {
            return {
                isEdit: this.isEdit,
                addItems: this.addItems
            }
        }
    },
    watch: {
        editAddItems: {
            immediate: true,
            handler ({ isEdit, addItems }) {
                // todo:is-edit和maxrowTal gridInit createDefaultLayout
                if (isEdit) {
                    this.maxRowTotal = 40
                } else {
                    if (addItems.length > 0) {
                        // todo:动态计算maxrowTal
                        const items = addItems.map(item => item.y + item.h)
                        const maxY = Math.max(...items)
                        this.maxRowTotal = maxY > 2 ? maxY : 3
                    } else {
                        this.maxRowTotal = 3
                    }
                }
                // this.gridInit()
            }
        },
        viewItems: {
            immediate: true,
            deep: true,
            handler (val) {
                this.views = val
            }
        }
    },
    created () {
        this.getEmployeeWidget()
    },
    mounted () {
        // this.gridInit()
    },
    methods: {
        layoutUpdatedEvent (newLayouts) {
            if (this.moved) {
                const addItems = newLayouts.filter(
                    item => item.name !== 'addCard' && item.name !== 'default'
                )
                this.addItems = addItems
                this.updateWidgets()
                this.moved = false
            }
        },
        handleMove (i, newX, newY) {
            // this.drap = true
            this.addItems = this.addItems.map(item => {
                if (item.i === i) {
                    item.x = newX
                    item.y = newY
                }
                return item
            })
        },
        handleMoved (i, newX, newY) {
            // 移动结束
            this.moved = true
        },
        handleDialog (visible, config) {
            if (visible) {
                this.$refs.addDialog.handleOpenDialog('add', config)
            }
        },
        handleEditDialog ({ x, y, w, h, i, name, props }) {
            this.$refs.addDialog.handleOpenDialog('edit', {
                componentName: name,
                gridBox: {
                    x,
                    y,
                    w,
                    h,
                    i,
                    name
                },
                props
            })
        },
        handleRemove (target) {
            this.$confirm('确定删除组件吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.moved = true
                const index = this.addItems.findIndex(
                    item => item.i === target.i
                )
                this.addItems.splice(index, 1)
                // this.addItems = this.addItems.filter(item => item.i !== target.i)
            })
        },
        handleEdit (value) {
            this.isEdit = value
        },
        handleAddWidget (config) {
            // 添加组件到布局
            this.moved = true
            const { gridBox, componentName } = config
            const index = this.addItems.findIndex(item => item.i === gridBox.i)
            if (index === -1) {
                this.addItems.push({
                    ...gridBox,
                    name: componentName,
                    static: false,
                    component: () =>
                        import(
                            'modules/desktop/widgets/' + componentName + '.vue'
                        )
                })
            } else {
                this.addItems.splice(index, 1, {
                    ...gridBox,
                    name: componentName,
                    static: false,
                    component: () =>
                        import(
                            'modules/desktop/widgets/' + componentName + '.vue'
                        )
                })
            }
            // this.defaultItems = this.defaultItems.reduce((prev, current) => {
            //     if (current.i === gridBox.i) {
            //         // 替换掉当前i相同的空白组件
            //         ['x', 'y', 'w', 'h', 'i'].forEach(item => {
            //             current[item] = gridBox[item]
            //             current.props[item] = gridBox[item]
            //         })
            //         current.component = () => import('components/search-query/' + componentName + '.vue')
            //     } else {
            //         const { x, y, w, h } = current
            //         const gridX = gridBox.x
            //         const gridY = gridBox.y
            //         const gridW = gridBox.w
            //         const gridH = gridBox.h
            //         if (x >= gridX && x < gridX + gridW && y >= gridY && y < gridY + gridH) {
            //             // 清除添加组件所站位范围内的空白添加组件
            //             current = null
            //         }
            //     }
            //     current && prev.push(current)
            //     return prev
            // }, [])
        },
        handleEditWidget () {
            // 编辑组件
        },
        async getEmployeeWidget () {
            const { data = [] } = await oaSvc.getEmployeeWidget()
            this.addItems = data.map(item => {
                const {
                    widgetNo: name,
                    customX: x,
                    customY: y,
                    customWidth: w,
                    customHeight: h
                } = item
                return {
                    i: name,
                    x,
                    y,
                    w,
                    h,
                    name,
                    static: false,
                    component: () =>
                        import('modules/desktop/widgets/' + name + '.vue'),
                    gridBox: {
                        i: name,
                        x,
                        y,
                        w,
                        h
                    }
                }
            })
        },
        async updateWidgets () {
            const addItems = this.views.filter(
                item => item.name !== 'addCard' && item.name !== 'default'
            )
            const params = addItems.map(item => {
                const {
                    name: widgetNo,
                    x: customX,
                    y: customY,
                    w: customWidth,
                    h: customHeight
                } = item
                return {
                    widgetNo,
                    customX,
                    customY,
                    customWidth,
                    customHeight
                }
            })
            const { data } = await oaSvc.updateEmployeeWidget(params)
            // this.getEmployeeWidget()
        }
        // addNewWidget: function () {
        //     const node = this.$options.items[this.count] || {
        //         x: Math.round(12 * Math.random()),
        //         y: Math.round(5 * Math.random()),
        //         w: Math.round(1 + 3 * Math.random()),
        //         h: Math.round(1 + 3 * Math.random())
        //     }
        //     node.id = node.content = String(this.count++)
        //     this.grid.addWidget(node)
        // }
    }
}
</script>
<style lang="scss">
.layout-grid {
    padding: 0;
    background-color: #e4ebf3;
}

.mask-lock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(105, 146, 188, 0.6);
    border-radius: 0.4rem;
    transition: all 0.3s;

    i {
        margin-left: 0.71rem;
        font-size: 32px;
        color: #fff;
        cursor: pointer;
        transition: transform 0.3s;
        transform: scale(0);

        &:first-of-type {
            margin-left: 0;
        }
    }

    &:hover {
        background: rgba(105, 146, 188, 0.9);

        i {
            transform: scale(1);
        }
    }
}

.custom-grid-item {
    touch-action: none; // Consider adding CSS "touch-action: none" to this element
}

.vue-grid-item.vue-grid-placeholder {
    border-radius: 0.4rem;
}
</style>
