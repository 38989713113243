<template>
    <div class="component-list">
        <div
            v-for="item in widgets"
            :key="item.id + item.widgetNo"
            class="component-list-item"
            :class="{'component-list-item-selected': selected === item.widgetNo}"
            @click="handleSelected(item)"
        >
            <el-image
                class="custom-image"
                fit="fill"
                lazy
                :src="item.iconUrl"
            >
                <div slot="error" class="el-image__error">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
            <div class="detail">
                <div class="item">
                    <span class="label">
                        组件名称：
                    </span>
                    <span class="info">{{ item.title }}</span>
                </div>
                <div class="item">
                    <span class="label">
                        关联模块：
                    </span>
                    <span class="info">{{ item.widgetGroup }}</span>
                </div>
                <div class="item">
                    <span class="label">
                        业务标签：
                    </span>
                    <span class="info">{{ item.tags }}</span>
                </div>
                <div class="item">
                    <span class="label">
                        默认尺寸：
                    </span>
                    <span class="info">{{ item.width }} * {{ item.height }}</span>
                </div>
                <div class="item w100">
                    <span class="label">
                        简要说明：
                    </span>
                    <span class="info">{{ item.summary }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ComponentList',
    props: {
        widgets: {
            type: Array,
            default: () => []
        },
        selected: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleSelected (selected) {
            this.$emit('handle-selected', selected)
        }
    }
}
</script>
<style lang="scss" scoped>
.component-list {
    height: 100%;
    overflow-y: auto;

    &-item {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 1.5rem;
        padding: 0.2rem;
        margin-bottom: 0.1rem;
        overflow: hidden;
        border: 2px solid #f0f0f0;
        border-radius: 0.1rem;

        .custom-image {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.24rem;
            border: 1px solid rgb(240 240 240 / 60%);
            // background-color: #f0f0f0;
            border-radius: 4px;
        }

        .detail {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            overflow: hidden;

            .item {
                display: flex;
                width: 50%;
                margin-bottom: 0.1rem;
                font-size: 0.14rem;
                font-weight: 400;
                color: #909399;
            }

            .label {
            }

            .info {
                flex: 1;
                overflow: hidden;
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .w100 {
                width: 100%;
                margin-bottom: 0;

                .info {
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    white-space: unset;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
        }
    }

    &-item-selected {
        border: 2px solid #69acf1;

        &::before {
            position: absolute;
            top: -0.02rem;
            left: -0.02rem;
            width: 0.4rem;
            height: 0.4rem;
            content: '';
            background-image: url('~@/assets/images/corner_mark.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}
</style>
