<template>
    <div class="finance-desktop desktop">
        <!-- 财务工作台 -->
        <group-tab :groups="groups" @groupChange="changeOrg"></group-tab>
        <el-scrollbar style="height: 100%">
            <div class="finance-desktop-main">
                <weatherSmall />
                <cash-small
                    :date="currentDate"
                    :totalAmount="
                        moneyFilter(cashRestInfo.tabTotalAmount, true, 8)
                    "
                    :data="xjSmallCardData"
                    icon="iconfont icon-xianjinyue"
                ></cash-small>
                <cash-small
                    itemTitle="核销应收"
                    :totalAmount="moneyFilter(hxInfo.tabTotalReceive, true, 8)"
                    :data="hxSmallCardData"
                    icon="iconfont icon-hexiaoyingshou"
                ></cash-small>
                <cash-small
                    itemTitle="应付"
                    :data="yfSmallCardData"
                    :totalAmount="moneyFilter(yfInfo.tabTotalPayment, true, 8)"
                    icon="iconfont icon-yingfu"
                ></cash-small>
                <Pending class="item-pending" />
                <cash-medium
                    :title="contant.financeDesktopText.GOODS_REST"
                    :columns="chColumns"
                    :sumData="chSumData"
                    :datas="chData"
                    icon="iconfont icon-cunhuojine"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="chParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.GOODS_REST
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </cash-medium>
                <cash-medium
                    :title="contant.financeDesktopText.CASH_REST"
                    :columns="xjColumn"
                    :datas="xjData"
                    :sumData="null"
                    :showDate="true"
                    :parenterId="Number(xjParenterId)"
                    :totalAmount="moneyFilter(cashRestInfo.totalAmount)"
                    handleIcon="iconfont icon-bianji"
                    @get-cash-reset="cashDateChange"
                    icon="iconfont icon-xianjinyue"
                    ref="cashRest"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="xjParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.CASH_REST
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </cash-medium>
                <!-- <cash-medium
                    :title="contant.financeDesktopText.CHECK_RECEIVE"
                    :columns="hxColumns"
                    :sumData="hxSumData"
                    :datas="hxData"
                    :partners="partners"
                    :allPartners="allPartners"
                    @handle-refresh="getCheckReceiveList"
                    handleIcon="iconfont icon-benzhouxinzengfenxiaoshang"
                    icon="iconfont icon-hexiaoyingshou"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="hxParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.CHECK_RECEIVE
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </cash-medium> -->
                <!-- 核销应收 -->
                <tableInCard
                    :title="contant.financeDesktopText.CHECK_RECEIVE"
                    :columns="hxColumns"
                    :datas="hxData"
                    :partners="partners"
                    :allPartners="allPartners"
                    handleIcon="iconfont icon-benzhouxinzengfenxiaoshang"
                    icon="iconfont icon-yingfu"
                    @handle-refresh="getCheckReceiveList"
                    ref="hxCard"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="hxParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.CHECK_RECEIVE
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                    <template v-slot:sumArea>
                        <div
                            class="table-in-card-main__top"
                            v-if="hxSumData && hxSumData.length > 0"
                        >
                            <div
                                v-for="sumItem in hxSumData"
                                :key="sumItem.title"
                            >
                                <p class="title">{{ sumItem.title }}</p>
                                <p class="value">
                                    <!-- <span>￥</span> -->
                                    {{ sumItem.value }}
                                </p>
                            </div>
                        </div>
                    </template>
                </tableInCard>
                <!-- <cash-medium
                    :title="contant.financeDesktopText.ACTUAL_PAY"
                    :sumData="yfSumData"
                    :columns="yfColumns"
                    :datas="yfData"
                    icon="iconfont icon-yingfu"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="yfParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.ACTUAL_PAY
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </cash-medium> -->
                <!-- 应付 -->
                <tableInCard
                    :title="contant.financeDesktopText.ACTUAL_PAY"
                    :columns="yfColumns"
                    :datas="yfData"
                    icon="iconfont icon-yingfu"
                >
                    <template v-slot:cashOption>
                        <el-select
                            style="width: 1.2rem"
                            v-model="yfParenterId"
                            placeholder="请选择"
                            @change="
                                parenterChange(
                                    contant.financeDesktopText.ACTUAL_PAY
                                )
                            "
                            size="small"
                        >
                            <el-option
                                v-for="item in partners"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                    <template v-slot:sumArea>
                        <div
                            class="table-in-card-main__top"
                            v-if="yfSumData && yfSumData.length > 0"
                        >
                            <div
                                v-for="sumItem in yfSumData"
                                :key="sumItem.title"
                            >
                                <p class="title">{{ sumItem.title }}</p>
                                <p class="value">
                                    <!-- <span>￥</span> -->
                                    {{ sumItem.value }}
                                </p>
                            </div>
                        </div>
                    </template>
                </tableInCard>
                <FinanceCard
                    v-if="groups && groups.length > 0"
                    :groupId="Number(currentGroupInfo.groupId)"
                />
                <GoodsOperateChart
                    ref="goodsOperatorCard"
                    :group="currentGroupInfo"
                    :groupId="Number(currentGroupInfo.groupId)"
                    :title="
                        currentGroupInfo.leaf
                            ? 'TOP5商品经营图'
                            : 'TOP5店铺经营图'
                    "
                />
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import oaSvc from 'services/oa'
import tradeSvc from 'services/trade'
import financeSvc from 'services/finance'
import groupTab from './components/orgs'
import cashSmall from './cards/cashSmall'
import cashMedium from './cards/cashMedium'
import Pending from './cards/pending'
import FinanceCard from './cards/finance'
import GoodsOperateChart from './cards/goodsOperate'
import contant from './cards/contant'
import weatherSmall from './cards/weatherSmall'
import tableInCard from './cards/tableInCard'
import {
    moneyFilter,
    makeDataToMap,
    assembleStatisticalData,
} from '@/utils/index'
export default {
    name: 'FinanceDesktop',
    components: {
        groupTab,
        cashSmall,
        cashMedium,
        Pending,
        FinanceCard,
        GoodsOperateChart,
        weatherSmall,
        tableInCard,
    },
    data() {
        return {
            contant,
            partners: [{ value: 0, label: '全部' }],
            groups: [],
            currentGroupInfo: {},

            xjParenterId: 0,
            xjColumn: ['账户', '余额'],
            xjData: [],
            xjSmallCardData: [],
            cashRestInfo: {},

            hxParenterId: 0,
            hxInfo: {},
            hxSmallCardData: [],
            hxSumData: [],
            // hxColumns: ['总应收清单'],
            hxColumns: [
                {
                    label:'合作伙伴',
                    link: true,
                    prop: 'partnerAlias',
                    align: 'left'
                },
                {
                    label:'金额',
                    prop:'receivableAmount',
                    sortable: true,
                    align: 'right'
                },
                {
                    label:'时间',
                    prop: 'receiveTime',
                    sortable: true,
                    align: 'right'
                },
            ],
            hxData: [],

            yfParenterId: 0,
            yfColumns: [
                {
                    label: '合作伙伴',
                    prop: 'receiverPartnerAlias',
                    align: 'left'
                },
                {
                    label: '金额',
                    prop: 'payAmount',
                    sortable: true,
                    align: 'right'
                },
                {
                    label: '时间',
                    prop: 'payingTime',
                    sortable: true,
                    align: 'right'
                },
            ],
            yfSumData: [],
            yfSmallCardData: [],
            yfInfo: {},
            yfData: [],

            chParenterId: 0,
            chColumns: ['品牌', '库存额', '在途额'],
            chSumData: [],
            chInfo: {},
            chData: [],
            allPartners: [],
            currentDate: null,
        }
    },
    created() {
        this.getPartners()
        this.getUserGroups()
        this.getCashRestData() // xj
        this.getCheckReceiveList() // hx
        this.getPaymentList() //yf
        this.getStockList() // ch
    },
    methods: {
        moneyFilter,
        cashDateChange(date) {
            this.currentDate = this.$moment(date).format('YYYY.MM.DD')
            this.getCashRestData(date)
        },
        async getPartners() {
            try {
                const { data = [] } = await financeSvc.getPartners()
                let arr = data.map((item) => {
                    let o = {}
                    o.value = item.id
                    o.label = item.partnerName
                    o.isOwn = item.isOwn
                    return o
                })
                this.allPartners = arr
                let ownPaetner = this.allPartners.filter((item) => item.isOwn)
                this.partners.push(...ownPaetner)
            } catch (error) {
                console.log(error)
            }
        },
        // 现金余额
        async getCashRestData(date) {
            try {
                const param = {
                    partnerId: this.xjParenterId,
                }
                if (date) {
                    param.balanceDate = this.$moment(date).format('YYYY-MM-DD')
                }
                const { data = {} } = await financeSvc.getCashRestData(param)
                if (!date) {
                    this.$nextTick(() => {
                        this.currentDate = this.$moment(
                            data.balanceDate
                        ).format('YYYY.MM.DD')
                        this.$refs.cashRest.date = data.balanceDate
                    })
                }
                this.cashRestInfo = data
                this.xjSmallCardData = this.handleSmallCardData(
                    data.partnerAliasAndAccountList,
                    'partnerAlias',
                    'partnerTotalAmount'
                )
                this.xjData = makeDataToMap(
                    data.accountBalanceVOList,
                    'accountTitle',
                    {
                        valueField: 'balanceAmount',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    }
                )
            } catch (e) {
                console.log(e)
            }
        },
        // 核销应收
        async getCheckReceiveList() {
            try {
                this.hxData = []
                const { data = {} } = await financeSvc.getCheckReceiveList(
                    this.hxParenterId
                )
                this.hxInfo = data
                this.hxSmallCardData = this.handleSmallCardData(
                    data.partnerAliasAndReceivableInfos,
                    'partnerAlias',
                    'partnerTotalAmount'
                )
                this.hxData = data.receivableInfoList
                // this.hxData = makeDataToMap(
                //     data.receivableInfoList,
                //     'partnerAlias',
                //     {
                //         valueField: 'receivableAmount',
                //         isMoney: true,
                //         hasUnit: true,
                //         unitLength: 7,
                //     },
                //     {
                //         valueField: 'receiveTime',
                //     }
                // )
                const current = this.$moment()
                this.hxData.forEach((item) => {
                    const scheduleEnd = this.$moment(item.receiveTime)
                    const diff = scheduleEnd.diff(current, 'days')
                    console.log(diff)
                    if (diff < 0) {
                        item.delay = true
                    } else {
                        item.delay = false
                    }
                })
                console.log(this.hxData)
                this.hxSumData = assembleStatisticalData(
                    data,
                    {
                        title: '核销应收',
                        valueField: 'totalReceive',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    },
                    {
                        title: '近七天核销应收',
                        valueField: 'sevenDayReceive',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    }
                )
                console.log(this.hxSumData)
            } catch (error) {
                console.log(error)
            }
        },
        // 应付金额
        async getPaymentList() {
            try {
                this.yfData = []
                const { data = {} } = await financeSvc.getPaymentList({
                    partnerId: this.yfParenterId,
                    source: 0,
                })
                this.yfInfo = data
                this.yfSmallCardData = this.handleSmallCardData(
                    data.partnerAliasAndPaymentList,
                    'partnerAlias',
                    'partnerTotalPaymentAmount'
                )
                this.yfData = data.paymentInfoList
                // this.yfData = makeDataToMap(
                //     data.paymentInfoList,
                //     'receiverPartnerAlias',
                //     {
                //         valueField: 'payAmount',
                //         isMoney: true,
                //         hasUnit: true,
                //         unitLength: 7,
                //     },
                //     {
                //         valueField: 'payingTime',
                //     }
                // )
                this.yfSumData = assembleStatisticalData(
                    data,
                    {
                        title: '近30天应付',
                        valueField: 'totalPayment',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    },
                    {
                        title: '近7天应付',
                        valueField: 'sevenDayPayment',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    }
                )
            } catch (error) {
                console.log(error)
            }
        },
        // 存货金额
        async getStockList() {
            try {
                const { data = {} } = await tradeSvc.getStockMoney(
                    this.chParenterId
                )
                this.chData = makeDataToMap(
                    data.brandStockAmountList,
                    'brandName',
                    {
                        valueField: 'stockAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    },
                    {
                        valueField: 'inRoadAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    }
                )
                this.chSumData = assembleStatisticalData(
                    data,
                    {
                        title: '存货总额',
                        valueField: 'stockTotalAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    },
                    {
                        title: '在途总额',
                        valueField: 'inRoadTotalAmt',
                        isMoney: true,
                        hasUnit: true,
                        unitLength: 7,
                    }
                )
            } catch (error) {
                console.log(error)
            }
        },
        handleSmallCardData(data, titleField, valueField) {
            if (data && data.length > 0) {
                const arr = data.map((item) => {
                    let obj = {}
                    obj.title = item[titleField]
                    obj.value = moneyFilter(item[valueField], true, 8)
                    return obj
                })
                return arr
            }
        },
        async getUserGroups() {
            const res = await oaSvc.getEmployeeInfo()
            this.groups = res.data.employeeGroupList
            this.currentGroupInfo = this.groups[0]
        },
        parenterChange(cardName) {
            switch (cardName) {
                case contant.financeDesktopText.GOODS_REST:
                    this.getStockList()
                    break
                case contant.financeDesktopText.CASH_REST:
                    this.getCashRestData()
                    break
                case contant.financeDesktopText.CHECK_RECEIVE:
                    this.getCheckReceiveList()
                    this.$refs.hxCard.tableLayout()
                    break
                case contant.financeDesktopText.ACTUAL_PAY:
                    this.getPaymentList()
                    break

                default:
                    break
            }
        },
        changeOrg(index) {
            this.currentGroupInfo = this.groups[index]
        },
    },
}
</script>
<style lang="less" scoped>
.finance-desktop {
    &-main {
        padding: 0.2rem;
        box-sizing: border-box;
        flex: 1;
        width: 100%;
        display: grid;
        grid-template-rows: repeat(5, 2rem);
        grid-template-columns: repeat(5, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 0.2rem;
        /deep/ .item-pending {
            grid-row-start: span 5;
        }
    }
}
</style>
