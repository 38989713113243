<template>
    <CardComponent class="product-matrix-card" :title="title" :icon="icon">
        <template v-slot:cardOptions>
            <i class="iconfont icon-shuoming"></i>
        </template>
        <template v-if="channels && channels.length">
            <el-row type="flex" class="mt24">
                <el-col
                    :sm="6"
                    :md="4"
                    :lg="4"
                    :xl="4"
                    class="custom-col"
                >
                    <span>店铺</span>
                    <el-select
                        v-model="params.channelId"
                        filterable
                        clearable
                        @change="handleChange('channel')"
                    >
                        <el-option
                            v-for="item in channels"
                            :key="item.id"
                            :label="item.channelName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col
                    :sm="6"
                    :md="4"
                    :lg="4"
                    :xl="4"
                    class="custom-col"
                >
                    <span>品类</span>
                    <!-- <el-select
                        v-model="params.categoryIds"
                        filterable
                        clearable
                        @change="handleChange('category')"
                    >
                        <el-option
                            v-for="item in categoryByChannel"
                            :key="item.id"
                            :label="item.categoryName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                    <select-all
                        v-model="params.categoryIds"
                        :options="categoryByChannel"
                        item-label="categoryName"
                        multiple
                        filterable
                        clearable
                        @change="handleChange('category')"
                    ></select-all>
                </el-col>
                <el-col
                    :sm="6"
                    :md="4"
                    :lg="4"
                    :xl="4"
                    class="custom-col"
                >
                    <span>品牌</span>
                    <el-select
                        v-model="params.brandId"
                        filterable
                        clearable
                        @change="handleChange('brand')"
                    >
                        <el-option
                            v-for="item in brandByChannelCategory"
                            :key="item.id"
                            :label="item.brandName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col
                    :sm="6"
                    :md="4"
                    :lg="4"
                    :xl="4"
                    class="custom-col"
                >
                    <span>定位</span>
                    <gb-dictionary
                        v-model="params.spuPointList"
                        class="w240 mr40"
                        group="店铺商品定位"
                        :clearable="true"
                        :filterable="true"
                        multiple
                        :is-show-all="true"
                        collapse-tags
                        placeholder="默认全部"
                        @change="handleChange('point')"
                    ></gb-dictionary>
                </el-col>
                <el-col
                    :sm="6"
                    :md="4"
                    :lg="4"
                    :xl="4"
                    class="custom-col"
                >
                    <span>竞品</span>
                    <select-all
                        v-model="params.relationBrandIds"
                        :options="relationBrand"
                        item-label="brandName"
                        multiple
                        filterable
                        clearable
                        @change="handleChange('relation')"
                    ></select-all>
                </el-col>
            </el-row>
            <div class="matrix-view">
                <section class="calibration">
                    <div
                        v-for="(item, index) in calibration"
                        :key="item"
                        class="calibration-row"
                    >
                        <span>{{ item + '元' }}</span>
                        <span
                            v-if="index === calibration.length - 1"
                            class="min-value"
                        >{{ minPrice + '元' }}</span>
                    </div>
                </section>
                <div class="matrix-view-scroll">
                    <div class="scroll">
                        <div
                            v-for="item in brandIdList"
                            :key="item"
                            class="martirx-procuct"
                        >
                            <div
                                v-for="row in intervals"
                                :key="row"
                                class="martirx-row"
                            >
                                <div
                                    v-for="col in matrix[item][row]"
                                    :key="col.id"
                                    class="martirx-col"
                                >
                                    <div
                                        class="product-logo"
                                        :style="
                                            `background-image: url(${col.photoUrl}`
                                        "
                                        :class="{
                                            'product-logo-default': !col.photoUrl
                                        }"
                                    ></div>
                                    <div class="product-info">
                                        <div>
                                            <span
                                                v-if="col.spuPoint"
                                                :class="`${col.spuPoint}-span`"
                                            >{{ col.spuPoint }}</span>
                                            <el-tooltip
                                                effect="dark"
                                                :content="col.spuAlias"
                                                placement="top-end"
                                                :disabled="!col.channelName"
                                            >
                                                <h6>{{ col.spuAlias || '-' }}</h6>
                                            </el-tooltip>
                                        </div>
                                        <el-tooltip
                                            effect="dark"
                                            :content="'¥' + col.price"
                                            placement="top-end"
                                            :disabled="col.price === null"
                                        >
                                            <h4>
                                                {{ '¥' + (col.price === null ? '-' : col.price) }}
                                            </h4>
                                        </el-tooltip>
                                        <el-tooltip
                                            effect="dark"
                                            :content="col.channelName"
                                            placement="top-end"
                                            :disabled="!col.channelName"
                                        >
                                            <h5>
                                                {{ col.channelName || '-' }}
                                            </h5>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name">{{ brandMap[item] }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="(channels && channels.length) && (!brandIdList || !brandIdList.length)" class="no-product">
                <img src="../../../assets/images/default.png" alt="数据缺省">
                <p>
                    骨贝小助手提醒您：当前模块尚无数据，请您在
                    <span
                        class="link"
                        @click="handleRouter('customer-goods')"
                    >平台商品</span>、
                    <span
                        class="link"
                        @click="handleRouter('customer-category')"
                    >平台品类</span>处进行维护
                </p>
            </div>
            <!-- <div class="matrix-view-scroll">
            </div> -->
        </template>
        <div v-if="!channels || !channels.length" class="empty-data">
            <img src="../../../assets/images/default.png" alt="数据缺省">
            <p>
                未增加渠道，请先去<span
                    class="link"
                    @click="handleRouter('customer-channel')"
                >渠道页面</span>增加渠道
            </p>
        </div>
    </CardComponent>
</template>
<script>
import _ from 'lodash'
import { mapState, mapMutations } from 'vuex'
import SelectAll from 'components/select/select-all'
import CardComponent from '../components/card'
import gbDictionary from '@/components/dictionary'
import sellSvc from 'services/sell'
import customerSvc from 'services/customer'

export default {
    name: 'ProductMatrix',
    components: {
        CardComponent,
        gbDictionary,
        SelectAll
    },
    beforeRouteLeave (to, from, next) {
        this.setChannels(null)
        next()
    },
    data () {
        return {
            title: '产品矩阵',
            icon: 'iconfont icon-chanpinjuzhen',
            params: {
                channelId: null, // 渠道ID列表 只填一个id
                categoryIds: null, // 平台品类ID,
                brandId: null, // 品牌ID
                relationBrandIds: null, // 竞品品牌ID
                spuPointList: null // 定位
            },
            // 下拉列表
            categoryByChannel: [],
            brandByChannelCategory: [],
            relationBrand: [],
            // 请求返回结果
            minPrice: 0, // 最小价格
            calibration: [], // 刻度
            intervals: [], // 间隔
            matrix: {}, // 产品矩阵
            brandMap: {}, // 品牌
            brandIdList: [] // 品牌id集合
        }
    },
    computed: {
        ...mapState({
            channels: state => state.customer.channels
        })
    },
    watch: {
        channels: {
            immediate: true,
            async handler (newVal) {
                if (newVal && newVal.length) {
                    this.params.channelId = newVal[0].id
                    this.categoryByChannel = []
                    this.brandByChannelCategory = []
                    this.relationBrand = []
                    ;['categoryIds', 'brandId', 'relationBrandIds'].forEach(
                        item => {
                            this.params[item] = null
                        }
                    )
                    try {
                        await this.getCategoryByChannel()
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }
    },
    methods: {
        async getChannels () {
            // 获取渠道列表
            const query = {
                enabled: true,
                foeFlag: false
            }
            const { data = [] } = await customerSvc.getChannels(query)
            this.setChannels(data)
        },
        handleRouter (name) {
            this.$router.push({
                name
            })
        },
        async getSpuInfoList () {
            // 根据渠道ID、平台品类ID、品牌ID、竞品品牌ID、定位获得相关商品信息
            try {
                const {
                    channelId,
                    categoryIds,
                    brandId,
                    relationBrandIds,
                    spuPointList
                } = this.params
                if (!channelId || _.isEmpty(categoryIds) || !brandId) {
                    // 如果渠道，品类，品牌为空不请求
                    return
                }

                const { data = {} } = await sellSvc.getSpuInfoList({
                    channelIds: [channelId],
                    categoryIds,
                    brandId,
                    relationBrandIds,
                    spuPointList
                })
                const {
                    maxPrice,
                    minPrice,
                    sellSpuPortraitList,
                    brandMap,
                    brandIdList
                } = data
                if (!sellSpuPortraitList.length) {
                    // 请求数据为空，重置之前响应过的数据
                    this.handleResetReponse()
                    return
                }
                // 计算间隔
                const interval = Math.ceil((maxPrice - minPrice) / 4)
                const calibration = [] // 刻度集
                const intervals = [] // 间隔集
                let i = minPrice
                // for (; i >= minPrice;) {
                //     if (i !== minPrice) {
                //         calibration.push(i)
                //     }
                //     const current = i
                //     i -= interval
                //     const cumu = i
                //     if (cumu >= minPrice) {
                //         intervals.push(`${cumu}-${current}`)
                //     }
                // }
                // this.calibration = calibration
                // this.intervals = intervals
                for (; i < maxPrice;) {
                    calibration.push(i + interval)
                    const current = i
                    i += interval
                    const cumu = i
                    if (cumu >= minPrice) {
                        intervals.push(`${current}-${cumu}`)
                    }
                }
                this.calibration = calibration.reverse()
                this.intervals = intervals.reverse()
                // 组装矩阵
                const matrix = {}
                // 根据价格区间和定位格式化数据
                const compare = (target, prices) => {
                    const nums = prices.split('-')
                    const [min, max] = nums
                    const currentList = target.filter(
                        item => item.price >= min * 1 && item.price < max * 1
                    )
                    const pointFn = point =>
                        currentList.filter(item => item.spuPoint === point)
                    const nullPoint = pointFn(null)
                    const S = pointFn('S')
                    const A = pointFn('A')
                    const B = pointFn('B')
                    const C = pointFn('C')
                    return [...S, ...A, ...B, ...C, ...nullPoint]
                }
                // 根据品牌归类数据
                const matrixFn = (target, brandId) => {
                    const brandProduct = target.filter(
                        el => el.brandId === brandId
                    )
                    const currentMap = {}
                    intervals.forEach(prices => {
                        currentMap[prices] = []
                        currentMap[prices] = compare(brandProduct, prices)
                    })
                    return currentMap
                }
                brandIdList.forEach(brandId => {
                    matrix[brandId] = matrixFn(sellSpuPortraitList, brandId)
                })
                this.minPrice = minPrice
                this.matrix = matrix
                this.brandMap = brandMap
                this.brandIdList = brandIdList
            } catch (error) {
                this.handleResetReponse()
            }
        },
        async getCategoryByChannel () {
            // 根据渠道ID获取相关的平台品类列表
            const { channelId } = this.params
            if (!channelId) {
                return
            }
            const { data } = await sellSvc.getCategoryByChannel({
                channelIds: [channelId]
            })
            this.categoryByChannel = data
            this.params.categoryIds = this.categoryByChannel && this.categoryByChannel.length ? [this.categoryByChannel[0].id] : null
            await this.getBrandByChannelCategory()
            await this.getRelationBrand()
            await this.getSpuInfoList()
        },
        async getBrandByChannelCategory () {
            // 根据渠道ID和平台品类ID获得相关品牌列表
            const { channelId, categoryIds } = this.params
            if (!channelId || _.isEmpty(categoryIds)) {
                return
            }
            const params = {
                channelIds: [channelId],
                categoryIds
            }
            const { data } = await sellSvc.getBrandByChannelCategory(params)
            this.brandByChannelCategory = data
            this.params.brandId = this.brandByChannelCategory && this.brandByChannelCategory.length ? this.brandByChannelCategory[0].id : null
        },
        async getRelationBrand () {
            // 根据渠道ID、平台品类ID和品牌ID获得相关竞品品牌列表
            const { channelId, categoryIds, brandId } = this.params
            if (!channelId || _.isEmpty(categoryIds) || !brandId) {
                return
            }
            const params = {
                channelIds: [channelId],
                categoryIds,
                brandId
            }
            const { data } = await sellSvc.getRelationBrand(params)
            this.relationBrand = data
        },
        handleResetReponse () {
            this.matrix = {}
            this.calibration = []
            this.intervals = []
            this.minPrice = null
            this.brandMap = {}
            this.brandIdList = []
        },
        async handleChange (name) {
            if (['channel', 'category', 'brand'].includes(name)) {
                // 渠道、品类、品牌
                // 重置请求过的数据
                this.handleResetReponse()
            }
            if (['channel', 'category'].includes(name)) {
                // 渠道、品类
                // 重置下拉列表
                this.brandByChannelCategory = []
                this.relationBrand = []
                // 重置下拉框选中值
                ;['brandId', 'relationBrandIds'].forEach(item => {
                    this.params[item] = null
                })
            }
            if (name === 'channel') {
                // 渠道
                this.categoryByChannel = []
                ;['categoryIds'].forEach(item => {
                    this.params[item] = null
                })
                await this.getCategoryByChannel()
                return
            }
            if (name === 'category') {
                // 品类
                await this.getBrandByChannelCategory()
                // return
            }
            // 除品类点击其他都可获取商品
            await this.getSpuInfoList()
            if (name === 'brand') {
                // 品牌
                this.relationBrand = []
                ;['relationBrandIds'].forEach(item => {
                    this.params[item] = null
                })
                await this.getRelationBrand()
            }
        },
        ...mapMutations('customer', {
            setChannels: 'UPDATE_CHANNELS'
            // setSpuList: 'UPDATE_SPULIST',
        })
    },
    mounted () {
        if (!this.channels || !this.channels.length) {
            this.getChannels()
        }
    }
}
</script>
<style lang="scss" scoped>
.product-matrix-card {
    background: #fff;
    border-radius: 40px;

    .mt24 {
        margin-top: 24px;
    }

    .icon-shuoming {
        margin-left: 10px;
        color: #c0c4cc;
    }

    .custom-col {
        display: flex;
        align-items: center;
        margin-left: 40px;

        &:first-of-type {
            margin-left: 24px;
        }

        span {
            width: 52px;
            font-size: 16px;
            font-weight: 400;
            color: #a7b6c3;
        }

        span + * {
            flex: 1;
            min-width: 180px;
        }
    }
}

.matrix-view-scroll {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;

    .scroll {
        display: flex;
        flex-wrap: nowrap;
        padding-right: 100px;
        padding-left: 5px;
    }
}

.matrix-view {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 85px;
    margin-left: 94px;

    .calibration {
        margin-bottom: 48px;

        .calibration-row {
            position: relative;
            width: 0;
            height: 140px;

            & > span {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 400;
                color: #a7b6c3;
                white-space: nowrap;
                transform: translate(-120%, -50%);
            }

            & > .min-value {
                top: auto;
                bottom: -8px;
                transform: translate(-120%, 0);
            }
        }
    }

    .martirx-procuct {
        position: relative;
        margin-bottom: 48px;

        &:not(:first-of-type) {
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 19px;
                height: calc(100% + 48px);
                content: '';
                border-left: 1px dashed #c0c4cc;
            }

            & > .martirx-row {
                position: relative;
                padding-left: 40px;
            }
        }
        // 最小边框长度
        &:first-of-type {
            .martirx-row {
                position: relative;

                &::before,
                &::after {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 100vw;
                    height: 1px;
                    content: "";
                    background: #dcdfe6;
                }

                &::after {
                    top: auto;
                    bottom: -1px;
                }
            }
        }

        .product-name {
            position: absolute;
            bottom: -44px;
            left: 50%;
            font-size: 18px;
            font-weight: 500;
            color: #636a6f;
            transform: translateX(-50%);
        }
    }

    .martirx-row {
        box-sizing: border-box;
        display: flex;
        // display: flex;
        // flex-wrap: wrap;
        height: 140px;
        padding: 4px 0;
        // border-top: 1px solid #DCDFE6;
        border-bottom: 1px solid #dcdfe6;

        &:first-of-type {
            border-top: 1px solid #dcdfe6;
        }

        .martirx-col {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 300px;
            height: 100%;
            padding: 0 16px;
            background: #fff;
            border-radius: 13px;
            box-shadow: 0 0 10px 0 #efefef;

            &:not(:first-of-type) {
                margin-left: 20px;
            }

            .product-logo {
                width: 100px;
                min-width: 100px;
                height: 100px;
                margin-right: 10px;
                background-color: #f0f0f0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }

            .product-logo-default {
                background-image: url('../../../assets/images/product_default.png') !important;
            }

            .product-info {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                width: calc(100% - 110px);
                height: 100%;
                padding: 16px 0;

                & > * {
                    width: 100%;
                    overflow: hidden;
                    text-align: right;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    line-height: 26px;

                    span {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin-top: -3px;
                        margin-right: 5px;
                        font-size: 10px;
                        font-weight: bold;
                        color: #fff;
                        background: #df5a68;
                        border-radius: 50%;
                    }

                    .S-span {
                        background: #df5a68;
                    }

                    .A-span {
                        background: #e6a23c;
                    }

                    .B-span {
                        background: #52c123;
                    }

                    .C-span {
                        background: #3c8c1a;
                    }

                    h6 {
                        max-width: calc(100% - 21px);
                        overflow: hidden;
                        font-size: 16px;
                        font-weight: 400;
                        color: #636a6f;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                h4 {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 26px;
                    color: #636a6f;
                }

                h5 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    color: #636a6f;
                }
            }
        }
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #606266;
    text-align: center;

    img {
        width: 400px;
    }

    .link {
        color: #409eff;
        cursor: pointer;
    }
}

.no-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #606266;
    text-align: center;

    img {
        width: 400px;
    }

    .link {
        color: #409eff;
        cursor: pointer;
    }
}
</style>
