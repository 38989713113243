<template>
    <draggable
        v-if="listSync.length >0"
        v-model="listSync"
        tag="ul"
        :group="groupName"
        class="draggle__list"
        animation="300"
        :disabled="disabled"
        @end="onEnd"
    >
        <li v-for="(item, index) in listSync" :key="index" class="draggle__item">
            <slot :data="item" class="draggle__content"> </slot>
        </li>
    </draggable>
    <Empty v-else :show-img="false"></Empty>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'
import Empty from 'components/empty'

export default {
    name: 'DraggleList',
    components: { Empty },
    props: {
        list: {
            type: Array,
            default: null
        },
        groupName: {
            type: String,
            required: true,
            default: null
        },
        disabled: Boolean // 禁止拖拽与否 默认false 即允许拖拽
    },
    data () {
        return {
            listSync: []
        }
    },
    watch: {
        list: {
            immediate: true,
            deep: true,
            handler (newValue) {
                this.listSync = _.cloneDeep(newValue)
            }
        }
    },
    created () {},
    methods: {
        onEnd () {}
    }
}
</script>
<style lang='less' scoped>
.draggle {
    /deep/  &__item {
        > div {
            position: relative;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 0.1rem;
            border-bottom: 1px solid #f0f0f0;
        }
    }
}
</style>
