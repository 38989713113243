<template>
    <!-- 工作台昨日日清组件 -->
    <ul v-if="hasData" class="yesterday-overs">
        <li v-for="item in yesterdayOverList" :key="item.id">
            <p class="name">
                <span>
                    {{ item.employeeName }}
                    <template
                        v-if="item.todoSummaryDetails && item.todoSummaryDetails.length > 0"
                    >
                        <!-- <i
                            v-for="o in item.todoSummaryDetails"
                            :key="o.id"
                            :class="{
                                iconfont: true,
                                'icon-weiwancheng': o.todoStatus === 0,
                                'icon-yiwancheng': o.todoStatus === 1,
                                'icon-yigezhi': o.todoStatus === 2,
                                'icon-yiquxiao': o.todoStatus === -1,
                            }"
                        ></i>
                        <template v-for="s in provideObj.todoStatusDicts">
                            <el-tooltip
                                v-if="s.itemValue === itemData.todoStatus"
                                :key="s.itemValue"
                                class="item"
                                effect="dark"
                                :content="s.title"
                                placement="top"
                            >
                                <i :class="s.class"></i>
                            </el-tooltip>
                        </template> -->
                        <div v-for="o in item.todoSummaryDetails" :key="o.id">
                            <template v-for="s in provideObj.todoStatusDicts">
                                <el-tooltip
                                    v-if="s.itemValue === o.todoStatus"
                                    :key="s.itemValue"
                                    class="item"
                                    effect="dark"
                                    :content="s.title"
                                    placement="top"
                                >
                                    <i :class="s.class"></i>
                                </el-tooltip>
                            </template>
                        </div></template>
                    </div>
                </span>
                <el-button
                    style="align-content: flex-end;"
                    type="text"
                    @click="showCheckDialog('detail', item.id)"
                >
                    详情
                </el-button>
            </p>

            <template
                v-if="item.todoSummaryQuestions && item.todoSummaryQuestions.length > 0"
            >
                <p v-for="o in item.todoSummaryQuestions" :key="o.id" class="text">
                    <span class="circle"></span>
                    {{ o.title }}
                </p>
            </template>
        </li>
    </ul>
    <Empty v-else />
</template>

<script>
import Empty from '@/components/empty'
export default {
    name: 'YesterdayOvers',
    components: { Empty },
    props: {
        yesterdayOverList: {
            type: Array,
            default: null
        }
    },
    data () {
        return {}
    },
    inject: ['provideObj'],
    computed: {
        hasData () {
            return this.yesterdayOverList && this.yesterdayOverList.length > 0
        }
    },
    methods: {
        showCheckDialog (sign, id) {
            this.$emit('show-check-dialog', sign, id)
        }
    }
}
</script>
<style lang="scss" scoped>
.yesterday-overs {
    padding: 0 0.2rem;

    i.icon-a-quandaibanweiwancheng {
        color: #dcdfe6;
    }

    i.icon-yiwancheng {
        color: #52c123;
    }

    i.assign.icon-yiwancheng,
    i.icon-yigezhi {
        color: #e6a23c;
    }

    i.icon-yiquxiao,
    i.icon-weiwancheng {
        color: #df5a68;
    }

    li {
        padding: 10px;
        border-bottom: 1px solid #f0f0f0;
    }

    .name {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #606266;

            i {
                margin-left: 5px;
                font-size: 16px;
            }
        }
    }

    .text {
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #606266;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .circle {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 12px;
        background-color: #e6a23c;
        border-radius: 50%;
    }
}
</style>
