<template>
  <header class="com-card-header">
    <div class="left">
      <i :class="$attrs.icon"></i>
      <span class="title">
        {{ $attrs.title }}
        <!-- <i class="iconfont icon-shuoming"></i> -->
      </span>
      <span v-if="$attrs.date" class="date">
        {{ $attrs.date }}
      </span>
      <el-select :style="{'width':width}" v-if="$attrs.options" v-model="value" placeholder="请选择" @change="handleFilter" size="small">
        <el-option v-for="item in $attrs.options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <slot name="options" v-else>
      </slot>
      <!-- <el-dropdown v-if="$attrs.filter">
        <span class="el-dropdown-link">
          {{ value }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" @click="handleClick">
          <el-dropdown-item  v-for="item in options" :key="item.value">{{ item.label }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <div class="right">
      <i v-if="$attrs.link" @click="handleClick" class="iconfont icon-tiaozhuan"></i>
      <slot v-else name="header"></slot>
    </div>
  </header>
</template>

<script>

export default {
  name: '',
  inheritAttrs: false,
  props: {
    width: {
      type: String,
      default: '1.4rem'
    },
  },
  data () {
    return {
      value: 1
    }
  },
  methods: {
    handleFilter (val) {
      console.log(val);
      // this.value = item.label;
      this.$emit('handleFilter', val);
    },
    handleClick () {
      if (this.$attrs.link) {
        this.$router.push(this.$attrs.link)
      }
    }
  },
}
</script>
<style lang='less' scoped>
header {
  display: flex;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    vertical-align: bottom;
    flex: 1;
    > i {
      font-size: 0.24rem;
      color: orange;
      margin-right: 0.1rem;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      color: #606266;
      line-height: 0.24rem;
      font-weight: 500;
      i {
        margin: 0 0.04rem;
        margin-top: 0.07rem;
        font-size: 0.14rem;
        color: #c0c4cc;
      }
    }
    .date {
      padding-top: 0.11rem;
      margin-left: 0.12rem;
      color: #909399;
      // line-height: .24rem;
      font-size: 0.14rem;
    }
    /deep/ .el-input__inner {
      border: none;
      background: rgba(255, 255, 255, 0);
      // width: 140px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    i {
      font-size: 28px;
      color: #a7b6c3;
      cursor: pointer;
    }
    i:hover {
      color: #d0d6da;
    }
    i+i, span + i {
      margin-left: 12px;
    }
  }
}
</style>
